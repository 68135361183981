import { Link } from "react-router-dom";

const LandingPage = () =>{
    return(
        <>
        <div class="main_wrapper" >
<div class="hero_sec" > 
    <div class="main-waves-span">
        <span class="wave1 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" ><img src="assets/img/ani1.png" width="50" class="img-fluid" alt="" /></span>
        <span class="wave2 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s" ><img src="assets/img/ani2.png" width="111" class="img-fluid" alt="" /></span>
        <span class="wave3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s"  ><img src="assets/img/ani3.png" width="60" class="img-fluid" alt="" /></span>
        <span class="wave4 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s"  ><img src="assets/img/ani4.png" width="111" class="img-fluid" alt="" /></span>
        <span class="wave5 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s"  ><img src="assets/img/ani5.png" width="82" class="img-fluid" alt="" /></span>
    </div>
    <div class="container" >
        <div class="row  gx-5 align-items-center" >
            <div class="col-lg-7" >
               <div class="hero_title" >
                <span class="title_sm" >Fast and Secure</span>
                <h1>
                    <em class="d-none" >Quani</em>
                   <img src="assets/img/title_text.svg" class="img-fluid" alt="" />
                </h1>
               </div>
            </div>
            <div class="col-lg-5" >
                <div class="hero_counter" >
                    <div class="hh_con text-end" >
                        <h3>
                            <small class="d-block" >Stakers</small>
                            <span class="" 
                            x-data="animation()"
                            x-init="animate(500)"
                            x-text="counter">
                            0
                          </span>K+
                        </h3>
                        <h3>
                            <small class="d-block"  >Stake</small>
                            $<span class="text-4xl font-bold text-blue-900" 
                            x-data="animation()"
                            x-init="animate(11024)"
                            x-text="counter">
                            0
                          </span>M+
                        </h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="hero_about" >
            
            <div class="row gx-5 align-items-center" >
                <div class="col-lg-6" >
                    <img src="assets/img/quani_logo.png"  class="img-fluid ab_img" alt="" />
                </div>
                <div class="col-lg-6" >
                    <div class="hero_about_c" >
                        <div class="b_bar my-4" ><span></span></div>
                        <h4 className="text-white" >
                        Gateway to Decentralized Wealth Revolutionize Your Digital Assets 

                        </h4> 
                        <p>
                        the essence of the QEALTH platform — redefines wealth for the modern investor. A token not just for trading, but for empowering and unifying a community that values financial freedom and innovation.
                        </p>
                        <a href="/dashboard" class="btn btn-white btn-white-outline d-flex align-items-center"> Stake Now <img src="assets/img/arrow_right.svg" class="ms-3" alt="" />   </a>
                    </div>
                </div>
        </div>

        </div>
    </div>
</div>


<div id="about" class="why_us" >
    <div class="main-waves-span_why">
        <span class="wave1_why wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" ><img src="assets/img/ani2.png" width="74" class="img-fluid" alt="" /></span> 
    </div>
   <div class="container" >
        <div class="row g-5" >
            <div class="col-lg-4" >
                <div class="why_title" >
                    <span class="min_title"> ABOUT  </span>
                    <h2 class="text-white" >Why Quani</h2>
                    <div class="b_bar mt-4" ><span></span></div>
                </div>
            </div>
            <div class="col-lg-2" ></div>
            <div class="col-lg-6" >
                <div class="why_p" >
                     <p> Because it's more than a currency; it's a commitment to the future of finance. With QUANI, experience unparalleled empowerment in wealth management, leveraging a token designed for collective prosperity and individual growth. </p>
                     <a href="/dashboard" class="btn btn-white btn-white-outline d-flex align-items-center"> Stake Now <img src="assets/img/arrow_right.svg" class="ms-3" alt="" />   </a>
                    
                </div>
            </div>
        </div>



        <div class="row justify-content-center g-4 cyr_row ">
            <div class="col-lg-4">
               <div class="cyr_card cyr_card2">
                  <span class="cyr_icon"><img src="assets/img/cyr_1.svg" width="35" class="img-fluid" alt=""/></span>
                 <h4> Seamless Integration  </h4>
                 <p>
                 Step into the world of QUANI Token, where each interaction is fluid and user-focused. Our platform removes the complexity from cryptocurrency transactions, offering you a hassle-free experience. Seamlessly integrate QUANI into your financial portfolio and witness the ease of digital asset management.
                 </p>
                 {/* <a href="/dashboard" class="btn-link btn-white d-flex justify-content-between mt-3" >
                    Stake Now
                    <img src="assets/img/arrow_right.svg" class="ms-3" alt="" />
                 </a> */}
               </div> 
            </div>  
            <div class="col-lg-4">
                <div class="cyr_card cyr_card2">
                   <span class="cyr_icon"><img src="assets/img/cyr_2.svg" width="35" class="img-fluid" alt=""/></span>
                  <h4>Strategic Growth </h4>
                  <p>
                  QUANI Token is more than a digital asset; it's a carefully crafted tool for strategic investment. We've designed QUANI with a long-term vision, ensuring that it not only meets the current market demands but also anticipates future financial trends. Invest strategically with QUANI for sustained growth and stability."
                  </p> 
                </div> 
             </div>  
             <div class="col-lg-4">
                <div class="cyr_card cyr_card2">
                   <span class="cyr_icon"><img src="assets/img/cyr_3.svg" width="35" class="img-fluid" alt=""/></span>
                  <h4>Smart Economy </h4>
                  <p>
                  Powered by innovative smart contract technology, QUANI Token stands at the vanguard of the smart economy. Our contracts execute with precision, upholding the integrity of your investments and the network. Engage with QUANI and be part of a smarter, more reliable economic ecosystem that's built for the future.
                  </p> 
                </div> 
             </div> 

          </div>

   </div>
</div>




<div id="how_it" class="why_us hdw_sec" >
    <div class="main-waves-span_why">
        <span class="wave1_why wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" ><img src="assets/img/ani2.png" width="74" class="img-fluid" alt="" /></span> 
    </div>
   <div class="container" >
        <div class="row g-5" >
            <div class="col-lg-4" >
                <div class="why_title" > 
                    <h2 class="text-white" >How Does <br/> Quani  Work  </h2>
                    <div class="b_bar mt-4" ><span></span></div>
                </div>
            </div>
            <div class="col-lg-2" ></div>
            <div class="col-lg-6" >
                <div class="why_p" >
                     <p>  QUANI Token is underpinned by the sophisticated roboTRDR, an AI-driven trading platform that enhances investment strategies through market trend analysis and automated trade execution. This integration of AI allows for a smarter, more responsive trading experience, contributing to the robustness of QUANI's financial ecosystem. The combination of QUANI's innovative tokenomics and roboTRDR's AI capabilities creates a secure, efficient, and potentially lucrative environment for both new and experienced investors. </p>
                     <a href="/dashboard" class="btn btn-white btn-white-outline d-flex align-items-center"> Stake Now <img src="assets/img/arrow_right.svg" class="ms-3" alt="" />   </a>
                    
                </div>
            </div>
        </div> 
        <div class="row justify-content-center cyr_row g-0 ">
            <div class="col-lg-4">
                <div class="wdw_card text-center h-100 border-top-none border-left-none" >
                    <img src="assets/img/globe_img.png" class="img-fluid" alt="" />
                </div>
            </div>
            <div class="col-lg-4">
                <div class="wdw_card   border-top-none  h-100" >
                    <div class="cyr_card"> 
                        <h4>Secure Transactions  </h4>
                        <p>
                        QUANI's infrastructure guarantees the safety of your assets and transactions, employing advanced encryption and smart contracts for peace of mind.
                        </p>
                        <a href="/dashboard" class="btn-link btn-white d-flex justify-content-between mt-4">
                            Stake Now
                           <img src="assets/img/arrow_right.svg" class="ms-3" alt=""/>
                        </a>
                      </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="wdw_card text-center  h-100 border-right-none border-top-none border-right-none" >
                    <img src="assets/img/globe_img.png" class="img-fluid" alt="" />
                </div>
            </div>
            
            <div class="col-lg-4">
                <div class="wdw_card     h-100  border-bottom-none border-left-none" >
                    <div class="cyr_card"> 
                        <h4>Exceptional Marketplace    </h4>
                        <p>
                        Access unparalleled staking terms within QUANI's marketplace, designed to elevate your investment portfolio through competitive yields and incentives.
                        </p>
                        <a href="/dashboard" class="btn-link btn-white d-flex justify-content-between mt-4">
                            Stake Now
                           <img src="assets/img/arrow_right.svg" class="ms-3" alt=""/>
                        </a>
                      </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="wdw_card text-center h-100  border-bottom-none  " >
                    <img src="assets/img/globe_img.png" class="img-fluid" alt="" />
                </div>
            </div>
            <div class="col-lg-4">
                <div class="wdw_card    h-100  border-bottom-none border-right-none" >
                    <div class="cyr_card"> 
                        <h4>Adaptable Staking:     </h4>
                        <p>
                        Flexibility is at the core of QUANI staking, offering liquid options for solo validators and diverse strategies, ensuring your assets work for you under various market conditions.
                        </p>
                        <a href="/dashboard" class="btn-link btn-white d-flex justify-content-between mt-4">
                            Stake Now
                           <img src="assets/img/arrow_right.svg" class="ms-3" alt=""/>
                        </a>
                      </div>
                </div>
            </div>
        </div>


   </div>
</div>




<div class="comm_sec" >
    <div class="container" >
        <div class="row justify-content-center" >
            <div class="col-lg-12" >
                  <div class="comm_card" >
                    <h2>    Join Our Community </h2>
                    <p>
                        Instantly discover answers in our support center for seamless crypto buying and selling with us.
                    </p>
                    <div class="social-links">
                        <ul>
                           <li>
                               <a title="Follow us on Facebook" target="_blank" href="https://www.facebook.com/Qalaxy-Labs-107489775111278"><i class="ri-facebook-circle-line"></i></a>
                           </li>
                           <li>
                               <a title="Follow us on Instagram" target="_blank" href="https://www.instagram.com/qalaxylabs/"><i class="ri-instagram-line"></i></a>
                           </li>
                           <li>
                               <a title="Follow us on Twitter" target="_blank" href="https://lnkd.in/ejhruvNP"><i class="ri-twitter-x-line"></i></a>
                           </li>
                           <li>
                               <a title="Follow us on Youtube" target="_blank" href="https://lnkd.in/ef_7KU43"><i class="ri-youtube-line"></i></a>
                           </li>
                           <li>
                               <a title="Follow us on Pinterest" target="_blank" href="https://lnkd.in/gf5j9Gtm"><i class="ri-pinterest-line"></i></a>
                           </li>
                       </ul>
                   </div>
                  </div>
            </div>
        </div>
    </div>
</div>



<div class="home_dn"  >
    <div class="container-fluid" >
        <div class="row justify-content-end align-items-center" >
            <div class="col-lg-6 col-md-8" >
                <div class="home_dn_img">
                    <img src="assets/img/Isolation_mode.png" class="isolation_mode img-fluid" alt="" />
                    <img src="assets/img/Isolation_mode2.png" class="isolation_mode2 img-fluid" alt="" />
                </div>
            </div>
        </div>
    </div>
    <div class="container" >
        <div class="footer" >
            <div class="gt" >
                <a href="#" class="go_top"> <img src="assets/img/arrow_right.svg" class="img-fluid" alt="" />  </a>
            </div>


            <div class="row justify-content-center ">
                <div class="col-lg-8 col-12   text-center">
                    <a class="  " href="#"><img src="assets/img/logo_footer.png" width="250" class="img-fluid" alt=""/></a>
                    <div class="f_copyright">
                        <ul class="ft_links my-4 my-lg-5  ">
                            <li class="icon-item"><a href="/">Home</a></li>
                            <li class="icon-item"><a href="#about">About Us</a></li>
                            <li class="icon-item"><a href="#how_it">How it Work</a></li>
                            <li class="icon-item"><a href="mailto:support@qalaxylabs.com"  >Contact Us</a></li> 
                        </ul>
                    </div>
                </div>
            </div>
            






        </div>
    </div>
    <div class="c_rights" >
        <div class="container" >
            <div class="row ">
                <div class="col-md-6">
                    <p class=" ">Copyright © 2022. All Rights Reserved Quani</p>
                </div>
                <div class="col-md-6 text-end">
                    <ul class="ft_links text-sm-end"> 
                        <li class="icon-item"><a href="#" data-bs-toggle="modal" data-bs-target="#policy_modal">Policy</a></li> 
                        <li class="icon-item"><a href="#" data-bs-toggle="modal" data-bs-target="#policy_modal" >Terms and conditions</a></li> 
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>





</div>



{/* <!-- policy_modal --> */}

<div class="modal fade" id="policy_modal" tabindex="-1" aria-labelledby="policy_modalLabel" aria-hidden="true">
<div class="modal-dialog modal-dialog-scrollable modal-xl">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel"> Privacy Policy title</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="modal-body"> 

        <div class="content_policy">


            <h4>What is Lorem Ipsum?</h4>
            <p>
                <b>Lorem Ipsum</b> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
            </p>

            <h4>Why do we use it?</h4>
            <p>
                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
            </p>

            <h4>What is Lorem Ipsum?</h4>
            <p>
                Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.  
            </p>
            <h4>What is Lorem Ipsum?</h4>
            <p>
                There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.
            </p>
            <h4>What is Lorem Ipsum?</h4>
            <p>
                <b>Lorem Ipsum</b> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
            </p>
            <h4>What is Lorem Ipsum?</h4>
            <p>
                "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"
            </p>

        </div>

    </div> 
  </div>
</div>
</div>
        </>
    )
}

export default LandingPage;