import QalaxyFooter from "../CustomComponents/QalaxyFooter"
import QalaxyHeader from "../CustomComponents/QalaxyHeader"

const Qalverse = () =>{
    return(
        <>
        <div class="site-content">
  {/* <!-- header-start--> */}
         {/* <QalaxyHeader/> */}
            {/* {/* <!-- header-closed--> */}

            <section class="coming-soon-new-wrapper qalvers">
                <div class="container">
                    <span class="shape-doup"><img src="qalaxyAssets/img/flight2.png" alt="" /></span>
                    <span class="shape-flight-pd"><img src="qalaxyAssets/img/flight.png" alt="" /></span>

                    <div class="main-coming-shape wow slideInRight z-index-1" data-wow-duration="3s" data-wow-delay="0.5s" >
                        <img src="qalaxyAssets/img/boycluid.png" alt="" />
                    </div>

                    <div class="coming-soon-main-img">
                        <img src="qalaxyAssets/img/logo-v.png" alt="" />
                    </div>
                    <p>
                        QALVERSE opens a gateway to a digital universe teeming with opportunities. Immerse yourself in an expansive metaverse where your avatar can explore, interact, and create a legacy. It's a space where every star is a
                        destination, and every journey tells a story.
                    </p>
                </div>
            </section>

            {/* <!-- qal-token-wrapper-start--> */}
            <section class="qal-token-wrapper">
                <div class="container">
                    <div class="token-wrapper-inner animation-1 full-height-box">
                        <div class="row align-items-center flex-sm-column-reverses">
                            <div class="col-md-8 m-reverses">
                                <div class="tokean-images mobilenone">
                                    <div class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
                                        <img src="qalaxyAssets/img/ani5.png" class="tokeimg3" />
                                    </div>
                                    <div class="wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                        <img src="qalaxyAssets/img/ani3.png" class="tokeimg1" />
                                    </div>
                                    <div class="wow fadeInUp" data-wow-duration="3s" data-wow-delay="0.2s">
                                        <img src="qalaxyAssets/img/ani4.png" class="tokeimg2" />
                                    </div>
                                </div>

                                <div class="qal-token-content">
                                    <h5>Explore the Realms</h5>
                                    <p>
                                        Dive into diverse realms within QALVERSE, each offering its unique allure. From the educational avenues of QALVERSITY to the thrilling landscapes of QALHIGH, your pursuit of knowledge, fun, and
                                        fortune knows no bounds.
                                    </p>
                                    <div class="comman-know-btn">
                                        <a href="javascript:;">Know more</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="tokean-images d-none mobile-visible">
                                    <div class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
                                        <img src="qalaxyAssets/img/ani5.png" class="tokeimg3" />
                                    </div>
                                    <div class="wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                        <img src="qalaxyAssets/img/ani3.png" class="tokeimg1" />
                                    </div>
                                    <div class="wow fadeInUp" data-wow-duration="3s" data-wow-delay="0.2s">
                                        <img src="qalaxyAssets/img/ani4.png" class="tokeimg2" />
                                    </div>
                                </div>

                                <div class="qal-shape-man">
                                    <img src="qalaxyAssets/img/ani1.png" class="round-round" />

                                    <figure class="wow slideInRight position-relative z-index-1" data-wow-duration="2s" data-wow-delay="0.2s"><img src="qalaxyAssets/img/ani2.png" alt="" /></figure>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="token-wrapper-inner right full-height-box">
                        <div class="row align-items-center flex-sm-column-reverses">
                            <div class="col-md-6">
                                <div class="qal-token-content">
                                    <h5>Community and Economy</h5>
                                    <p>
                                        Engage in a thriving economy powered by the QAL token. Connect with creators, educators, and entrepreneurs in a community that values collaboration and innovation. Your journey through QALVERSE is
                                        enriched by the relationships you build and the economy you participate in.
                                    </p>
                                    <div class="comman-know-btn">
                                        <a href="javascript:;">Know more</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="main-slider-box">
                                            <ul class="zooming-images">
                                                <li class="wow zoomIn" data-wow-duration="1s" data-wow-delay="0.1s"><img src="qalaxyAssets/img/slider/v1.png" /></li>
                                                <li class="wow zoomIn" data-wow-duration="2s" data-wow-delay="0.2s"><img src="qalaxyAssets/img/slider/v2.png" alt="" /></li>
                                                <li class="wow zoomIn" data-wow-duration="3s" data-wow-delay="0.3s"><img src="qalaxyAssets/img/slider/v3.png" alt="" /></li>
                                            </ul>
                                            <ul class="zooming-images two">
                                                <li class="wow zoomIn" data-wow-duration="2s" data-wow-delay="0.4s"><img src="qalaxyAssets/img/slider/v4.png" alt="" /></li>
                                                <li class="wow zoomIn" data-wow-duration="3s" data-wow-delay="0.5s"><img src="qalaxyAssets/img/slider/v5.png" alt="" /></li>
                                                <li class="wow zoomIn" data-wow-duration="1s" data-wow-delay="0.6s"><img src="qalaxyAssets/img/slider/v6.png" alt="" /></li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="layes-plays">
                                            <img src="qalaxyAssets/img/ani1.png" class="round-round" />

                                            <figure class="wow slideInRight position-relative z-index-1" data-wow-duration="2s" data-wow-delay="0.2s"><img src="qalaxyAssets/img/layersman.png" alt="" /></figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="token-wrapper-inner right full-height-box">
                        <div class="row align-items-center">
                            <div class="col-md-6">
                                <div class="qal-token-wrapper-animation">
                                    <div class="wave-passing">
                                        <span class="wave-passing-1 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s"><img src="qalaxyAssets/img/dotes1.png" /></span>
                                        <span class="wave-passing-2 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s"><img src="qalaxyAssets/img/dotes2.png" /></span>
                                        <span class="wave-passing-3 wow fadeInUp" data-wow-duration="3s" data-wow-delay="0.2s"><img src="qalaxyAssets/img/dotes3.png" /></span>
                                    </div>
                                    <div class="passive-img">
                                        <figure class="wow slideInUp" data-wow-duration="3s" data-wow-delay="0.3s"><img src="qalaxyAssets/img/layersman1.png" alt="" /></figure>
                                        <span class="waves"> <img src="qalaxyAssets/img/waves.png" class="wow zoomIn" data-wow-duration="1s" data-wow-delay="0.2s" /></span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="qal-token-content">
                                    <h5>Innovative Features</h5>
                                    <p>
                                        QALVERSE is a tapestry woven with the threads of cutting-edge technology. Here, blockchain's trust meets AI's intellect, and VR's immersion creates a symphony of digital experiences that are as
                                        boundless as the cosmos itself.
                                    </p>
                                    <div class="comman-know-btn">
                                        <a href="javascript:;">Know more</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- qal-token-wrapper-closed--> */}

            {/* <!--passive-income-wrapper-start--> */}
            <section class="passive-income-wrapper full-height-box">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="passivemain-inner">
                                <div class="main-waves-span">
                                    <span class="wave1 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s"><img src="qalaxyAssets/img/b1.png" alt="" /></span>
                                    <span class="wave2 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s"><img src="qalaxyAssets/img/b2.png" alt="" /></span>
                                    <span class="wave3 wow fadeInUp" data-wow-duration="3s" data-wow-delay="0.4s"><img src="qalaxyAssets/img/b3.png" alt="" /></span>
                                    <span class="wave4 wow fadeInUp" data-wow-duration="4s" data-wow-delay="0.1s"><img src="qalaxyAssets/img/b4.png" alt="" /></span>
                                    <span class="wave5 wow fadeInUp" data-wow-duration="5s" data-wow-delay="0.2s"><img src="qalaxyAssets/img/b5.png" alt="" /></span>
                                </div>

                                <div class="passive-img">
                                    <figure class="wow slideInUp" data-wow-duration="3s" data-wow-delay="0.3s"><img src="qalaxyAssets/img/yogaman.png" alt="" /></figure>
                                    <span class="waves"> <img src="qalaxyAssets/img/waves.png" class="wow zoomIn" data-wow-duration="1s" data-wow-delay="0.2s" /></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="passive-content top">
                                <h3>
                                    Begin your adventure in QALVERSE today.
                                    <span>Get Started in QALVERSE </span>
                                    Secure your QAL tokens
                                </h3>

                                <p>
                                    Step into a realm where your actions shape the world. With our user-friendly portal, the universe is just a click away.
                                </p>
                                <div class="comman-know-btn">
                                    <a href="javascript:;">Know more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!--passive-income-wrapper-closed--> */}

            {/* <!-- qalverse-wrapper-strat--> */}
            <section class="qalverse-wrapper full-height-box">
                <div class="container">
                    <div class="row align-items-center flex-sm-column-reverses">
                        <div class="col-md-6">
                            <figure class="wow slideInUp" data-wow-duration="2s" data-wow-delay="0.4s"><img src="qalaxyAssets/img/man-shape-5.png" alt="" /></figure>
                        </div>
                        <div class="col-md-6">
                            <div class="counter-wrapper wow fadeInUp" data-wow-duration="4s" data-wow-delay="0.3s">
                                <div class="main-count">
                                    <div class="up-count">
                                        <h5>Join the Adventure</h5>
                                    </div>
                                </div>

                                <h4>
                                    Ready to claim your place in the stars? Join QALVERSE now and be part of the next frontier in digital exploration. Create, connect, conquer<br />
                                    – your QALVERSE adventure awaits.
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- footer-start--> */}
           <QalaxyFooter/>
            {/* <!-- footer-closed--> */}
        </div>
        {/* <!-- meta-mask-modal-start--> */}
        <div class="meta-mask modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="modal-body">
                        <div class="comman-content-meta">
                            <h3>Hey there</h3>
                            <span>you can</span>
                            <h3>connect to wallet</h3>
                            <span>now</span>
                        </div>
                        <div class="select-meta-box">
                            <div class="select-box-comman">
                                <select class="form-select" aria-label="Default select example">
                                    <option selected>metamask</option>
                                    <option value="1">Wallet options</option>
                                </select>
                            </div>
                            <button>confirm</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Qalverse