import QalaxyFooter from "../CustomComponents/QalaxyFooter"
import QalaxyHeader from "../CustomComponents/QalaxyHeader"

const QalaxyLandingPage = () =>{
    return(
        <>
          <div class="site-content">
          {/* <!-- header-start--> */}
         {/* <QalaxyHeader/> */}
            {/* <!-- header-closed-->
            <!--- feature-slider-wrapper-start--> */}
            <section class="fature-slider-wrapper">
                <div class="start">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>

                <div class="container">
                    <div class="wow slideInRight position-relative z-index-1" data-wow-duration="3s" data-wow-delay="0.5s">
                        <img src="qalaxyAssets/img/man-shape.png" alt="" id="opens" class="shpeone" />
                    </div>
                    <figure><img src="qalaxyAssets/img/round.png" alt="" /></figure>
                    <div class="slider-content-inner">
                        <h1>The Name Says It All</h1>
                        <p>
                            Qalaxy Labs is your portal to the future, merging immersive metaverse exploration with high-yield financial opportunities. Journey through QALVERSE, grow your wealth with QEALTH, and engage with our vibrant
                            community through TAYC.
                        </p>
                        <div class="comman-know-btn">
                            <a href="javascript:;">Know more</a>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--- feature-slider-wrapper-closed-->

            <!--passive-income-wrapper-start--> */}
            <section class="passive-income-wrapper">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="passivemain-inner">
                                <div class="main-waves-span">
                                    <span class="wave1 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s"><img src="qalaxyAssets/img/b1.png" alt="" /></span>
                                    <span class="wave2 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s"><img src="qalaxyAssets/img/b2.png" alt="" /></span>
                                    <span class="wave3 wow fadeInUp" data-wow-duration="3s" data-wow-delay="0.4s"><img src="qalaxyAssets/img/b3.png" alt="" /></span>
                                    <span class="wave4 wow fadeInUp" data-wow-duration="4s" data-wow-delay="0.1s"><img src="qalaxyAssets/img/b4.png" alt="" /></span>
                                    <span class="wave5 wow fadeInUp" data-wow-duration="5s" data-wow-delay="0.2s"><img src="qalaxyAssets/img/b5.png" alt="" /></span>
                                </div>

                                <div class="passive-img">
                                    <figure class="wow slideInUp" data-wow-duration="3s" data-wow-delay="0.3s"><img src="qalaxyAssets/img/yogaman.png" alt="" /></figure>
                                    <span class="waves"> <img src="qalaxyAssets/img/waves.png" class="wow zoomIn" data-wow-duration="1s" data-wow-delay="0.2s" /></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="passive-content top">
                                <h3>
                                    BE THE PIONEER IN GENERATING
                                    <span>Passive Income</span>
                                    with QEALTH
                                </h3>

                                <p>
                                    Multiply your crypto assets with QEALTH. Our AI-driven trading platform, roboTRDR, offers a hands-off approach for maximizing returns, providing you with a secure and intelligent investment path.
                                </p>
                                <div class="comman-know-btn">
                                    <a href="javascript:;">Know more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!--passive-income-wrapper-closed-->
            <!-- leading-innovation-wrapper-start--> */}
            <section class="leading-innovation-wrapper">
                <div class="container">
                    <div class="learning-content">
                        <h5>Leading Innovation. Technological Advancement. Collaboration.</h5>
                    </div>
                </div>
            </section>
            {/* <!-- leading-innovation-wrapper-closed--> */}

            {/* <!-- qal-token-wrapper-start--> */}
            <section class="qal-token-wrapper">
                <div class="container">
                    <div class="token-wrapper-inner animation-1">
                        <div class="row align-items-center flex-sm-column-reverses">
                            <div class="col-md-8 m-reverses">
                                <div class="tokean-images mobilenone">
                                    <div class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
                                        <img src="qalaxyAssets/img/ani5.png" class="tokeimg3" />
                                    </div>
                                    <div class="wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                        <img src="qalaxyAssets/img/ani3.png" class="tokeimg1" />
                                    </div>
                                    <div class="wow fadeInUp" data-wow-duration="3s" data-wow-delay="0.2s">
                                        <img src="qalaxyAssets/img/ani4.png" class="tokeimg2" />
                                    </div>
                                </div>

                                <div class="qal-token-content">
                                    <h5>Innovation Through QALVERSE</h5>
                                    <p>
                                        QALVERSE offers you a diverse, enriching virtual experience. Engage with bespoke digital realms, unlock new dimensions of interactivity, and pave the way for unparalleled virtual collaboration.
                                    </p>
                                    <div class="comman-know-btn">
                                        <a href="javascript:;">Know more</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="tokean-images d-none mobile-visible">
                                    <div class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
                                        <img src="qalaxyAssets/img/ani5.png" class="tokeimg3" />
                                    </div>
                                    <div class="wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                        <img src="qalaxyAssets/img/ani3.png" class="tokeimg1" />
                                    </div>
                                    <div class="wow fadeInUp" data-wow-duration="3s" data-wow-delay="0.2s">
                                        <img src="qalaxyAssets/img/ani4.png" class="tokeimg2" />
                                    </div>
                                </div>

                                <div class="qal-shape-man">
                                    <img src="qalaxyAssets/img/ani1.png" class="round-round" />

                                    <figure class="wow slideInRight position-relative z-index-1" data-wow-duration="2s" data-wow-delay="0.2s"><img src="qalaxyAssets/img/ani2.png" alt="" /></figure>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="token-wrapper-inner right full-grid-height">
                        <div class="row align-items-center">
                            <div class="col-md-6">
                                <div class="qal-token-wrapper-animation">
                                    <div class="wave-passing">
                                        <span class="wave-passing-1 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s"><img src="qalaxyAssets/img/dotes1.png" /></span>
                                        <span class="wave-passing-2 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s"><img src="qalaxyAssets/img/dotes2.png" /></span>
                                        <span class="wave-passing-3 wow fadeInUp" data-wow-duration="3s" data-wow-delay="0.2s"><img src="qalaxyAssets/img/dotes3.png" /></span>
                                    </div>
                                    <div class="passive-img">
                                        <figure class="wow slideInUp" data-wow-duration="3s" data-wow-delay="0.3s"><img src="qalaxyAssets/img/layersman1.png" alt="" /></figure>
                                        <span class="waves"> <img src="qalaxyAssets/img/waves.png" class="wow zoomIn" data-wow-duration="1s" data-wow-delay="0.2s" /></span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="qal-token-content">
                                    <h5>Robo TRDR</h5>
                                    <p>
                                        Making crypto-trading smart, futuristic, and provides 100% solution to manual crypto trading. The new-age, beta-driven AI-based technology for crypto investing that ensures zero errors with huge
                                        profits and greater returns, irrespective of the market conditions.
                                    </p>
                                    <div class="comman-know-btn">
                                        <a href="javascript:;">Know more</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="token-wrapper-inner right full-grid-height">
                        <div class="row align-items-center flex-sm-column-reverses">
                            <div class="col-md-6">
                                <div class="qal-token-content">
                                    <h5>Community at TAYC</h5>
                                    <p>
                                        Join TAYC, our dedicated NFT platform, to collect and trade exclusive digital assets. Be part of a creative revolution, where community and innovation meet. Right from series of Apes to sale of
                                        landblocks to enter into the immersive world of QALVERSE
                                    </p>
                                    <div class="comman-know-btn">
                                        <a href="javascript:;">Know more</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="main-slider-box">
                                            <ul class="zooming-images">
                                                <li class="wow zoomIn" data-wow-duration="1s" data-wow-delay="0.1s"><img src="qalaxyAssets/img/slider/v1.png" /></li>
                                                <li class="wow zoomIn" data-wow-duration="2s" data-wow-delay="0.2s"><img src="qalaxyAssets/img/slider/v2.png" alt="" /></li>
                                                <li class="wow zoomIn" data-wow-duration="3s" data-wow-delay="0.3s"><img src="qalaxyAssets/img/slider/v3.png" alt="" /></li>
                                            </ul>
                                            <ul class="zooming-images two">
                                                <li class="wow zoomIn" data-wow-duration="2s" data-wow-delay="0.4s"><img src="qalaxyAssets/img/slider/v4.png" alt="" /></li>
                                                <li class="wow zoomIn" data-wow-duration="3s" data-wow-delay="0.5s"><img src="qalaxyAssets/img/slider/v5.png" alt="" /></li>
                                                <li class="wow zoomIn" data-wow-duration="1s" data-wow-delay="0.6s"><img src="qalaxyAssets/img/slider/v6.png" alt="" /></li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="layes-plays">
                                            <img src="qalaxyAssets/img/ani1.png" class="round-round" />

                                            <figure class="wow slideInRight position-relative z-index-1" data-wow-duration="2s" data-wow-delay="0.2s"><img src="qalaxyAssets/img/layersman.png" alt="" /></figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- qal-token-wrapper-closed-->
            <!-- qalverse-wrapper-strat--> */}
            <section class="qalverse-wrapper new">
                <div class="container">
                    <div class="row align-items-center flex-sm-column-reverses">
                        <div class="col-md-6">
                            <figure class="wow slideInUp" data-wow-duration="2s" data-wow-delay="0.4s"><img src="qalaxyAssets/img/man-shape-5.png" alt="" /></figure>
                        </div>
                        <div class="col-md-6">
                            <div class="counter-wrapper wow fadeInUp" data-wow-duration="4s" data-wow-delay="0.3s">
                                <div class="main-count">
                                    <div class="up-count">
                                        <h5>Up To</h5>
                                    </div>

                                    <div class="count">
                                        <h3><span id="count1" class="display-4"></span>60% <small> APY </small></h3>
                                    </div>
                                </div>

                                <h4>
                                    Simple Deposit <br />
                                    Daily Return
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- qalverse-wrapper-closed-->

            <!-- footer-start--> */}
            <QalaxyFooter/>
            {/* <!-- footer-closed--> */}
        </div>

        {/* <!-- meta-mask-modal-start--> */}

        <div class="meta-mask modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>

                    <div class="modal-body">
                        <div class="comman-content-meta">
                            <h3>Hey there</h3>
                            <span>you can</span>
                            <h3>connect to wallet</h3>
                            <span>now</span>
                        </div>

                        <div class="select-meta-box">
                            <div class="select-box-comman">
                                <select class="form-select" aria-label="Default select example">
                                    <option selected>metamask</option>
                                    <option value="1">Wallet options</option>
                                </select>
                            </div>

                            <button>confirm</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default QalaxyLandingPage