import Sidebar from "../CustomComponents/Sidebar";
import HeaderNavbar from "./HeaderNavbar";
import { useConnectWallet } from '@web3-onboard/react'
import { useEffect, useState } from "react"
import stakingData from './StakingContract.json'
import { ethers } from 'ethers'
import LoaderHelper from "../CustomComponents/Loading/LoaderHelper";
import usdt from "./UsdtContract.json"
import adminData from "./adminDepoite.json"


{/* Loader while featching values*/ }
const TransactionHistory = () => {
  const [transactions, setTransactions] = useState([])
  const [transactionValue, setTransactionValue] = useState();
  const [calculatedValues, setCalculatedValues] = useState([]);

  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet()


  let ethersProvider;
  let signer;


  useEffect(() => {
    if (!wallet) {
      connect();
    }
    else if (wallet) {
      ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any')
      signer = ethersProvider.getSigner();
      const QuaniStakingAddress = stakingData.address
      const QuaniStakingABI = stakingData.abi;
      const stakingInstance = new ethers.Contract(QuaniStakingAddress, QuaniStakingABI, signer);
      transactionHistory(wallet.accounts[0].address);
    }
  }, [wallet])

  const transactionHistory = async (accountAddress) => {
    const apiKey = 'EYFMCWEGW7QNUBMFKRNBCJV81EATI8P86R';
    /* 
    * @mainnet
    */
    const apiUrl = `https://api.bscscan.com/api?module=account&action=txlist&address=${accountAddress}&startblock=37789406&endblock=99999999&sort=desc&page=1&offset=0&apikey=${apiKey}`;
    try {
      const response = await fetch(apiUrl);
      LoaderHelper.loaderStatus(true)
      const data = await response.json();
      setTransactions(data.result)
      LoaderHelper.loaderStatus(false)
      return data.result;
    } catch (error) {
      LoaderHelper.loaderStatus(false)
      console.error('Error fetching transaction history:', error);
      return [];
    }
  }


  const calculateValue = async (inputData) => {
    try {
      const iface = new ethers.utils.Interface(stakingData.abi);
      // console.log("iface",iface);
      const hexString = iface.decodeFunctionData("stakeTokens", inputData);
      let value = hexString.toString();
      value = ethers.utils.formatUnits(value)
      return value;
    } catch (error) {
      return 'NA';
    }
  }
  const data = transactions.filter(item => (item.to == 
    adminData.address        
    ||
    usdt.address         
    ||
    stakingData.address      
  )
    )

  useEffect(() => {
    const calculateAllValues = async () => {
      const newCalculatedValues = [];
      for (const transaction of transactions) {
        let  calculatedValue = await calculateValue(transaction.input);
        newCalculatedValues.push(calculatedValue);
        
      }
      setCalculatedValues(newCalculatedValues);
    };

    calculateAllValues();
  }, [transactions]);



  return (
    <>
      <div class="wrap">
        <div class="content content-fluid">
          <div class="container-xl wide-xl">
            <div class="content-body">
              <HeaderNavbar />
              <div class="sec_2 pt-0">
                <div class="row g-3">
                  <div class="col-lg-12">
                    <div class="card  ">
                      <div class="card-body p-0">
                        <div class="table-responsive">
                          <table class="table table-dark mb-0">
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">From</th>
                                <th scope="col">To</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Transaction Hash</th>
                                <th scope="col">Date/Time</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.length > 0 ? data.map((item, index) => (
                                <tr>
                                  <th scope="row">{index + 1}</th>
                                  <td><div className="id_hash" >{item?.from}</div></td>
                                  <td> <div className="id_hash"  > {item?.to} </div></td>
                                  <td> {

                                  calculatedValues[index]

                                  
                                  
                                  } </td>
                                  <td><div className="id_hash"   > <a href={`https://testnet.bscscan.com/tx/${item?.hash}`} target="_blank">{item?.hash}</a> </div></td>
                                  <td>{`${new Date(item?.timeStamp * 1000).toLocaleString()}`}</td>
                                </tr>
                              ))
                                : <tr><th colspan="7" className="no_data_row" >
                                  <h3 className="no_data" > 
                                  No Data Available 
                                  </h3>
                                  </th></tr>}
                            </tbody>
                          </table>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default TransactionHistory