
const ComingSoon = () => {

    return (
        <>
        <div class="site-content">
            {/* <!-- header-start--> */}
        {/* <header>
                <div class="container">
                    <nav class="navbar navbar-expand-lg">
                        <a class="navbar-brand" href="index.html"><img src="img/logo.png" alt="logo" /></a>
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M12 3v2H3V3h9zm4 16v2H3v-2h13zm6-8v2H3v-2h19z" />
                            </svg>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav ml-auto">
                                <li class="nav-item">
                                    <a class="nav-link" href="index.html">Home</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="about.html">About Us</a>
                                </li>
                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Offerings
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <a class="dropdown-item" href="coming-soon2.html">TAYC</a>
                                        <a class="dropdown-item" href="qal-token.html">Qal Token</a>
                                        <a class="dropdown-item" href="robotrdr.html">Robo TRDT</a>
                                        <a class="dropdown-item" href="coming-soon.html">Qalverse</a>
                                    </div>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="community.html">Community </a>
                                </li>
                                  <li class="nav-item">
                                    <a class="nav-link" href="smart-staking.html">Smart Staking </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="img/Whitepaper.pdf">White Paper </a>
                                </li>
                            </ul>
                            <ul class="right-bar">
                                <li><a href="#" data-toggle="modal" data-target="#exampleModal">Connect Wallet</a></li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header> */}
            {/* <!-- header-closed--> */}
            <section class="coming-soon-new-wrapper">
                <div class="container">
                    <span class="shape-doup"><img src="qalaxyAssets/img/flight2.png" alt="" /></span>
                    <span class="shape-flight-pd"><img src="qalaxyAssets/img/flight.png" alt=""/></span>

                    <div class="main-coming-shape wow slideInRight  z-index-1" data-wow-duration="3s" data-wow-delay="0.5s">
                        <img src="qalaxyAssets/img/boycluid.png" alt="" />
                    </div>

                    <div class="coming-soon-main-img">
                        <img src="qalaxyAssets/img/coming-soong2.png" alt="" />
                    </div>
                    <div class="stad-btn text-center">
                        <a href="">Stay Tuned</a>
                    </div>
                </div>
            </section>
            {/* <!-- footer-start--> */}
            <footer>
                <div class="container">
                    <div class="footer-logo">
                        <a href=""><img src="qalaxyAssets/img/logo.png" alt="" /></a>
                        <p>
                            Qalaxy Labs is a project to take people into virtual QALVERSE, it will be launched once the community gets stronger and its 1st NFT series is minted over Polygon Matic. As the NFT once sold and owners when stake
                            that NFT’s will Qalaxy Labs the owner of the NFT will get the opportunity to earn native token (Polygon Matic) QAL.
                        </p>
                    </div>
                    <div class="contect-footer-inner">
                        <h4>Contact Us</h4>
                        <a href="mailto:support@qalaxylabs.com">support@qalaxylabs.com</a>
                        <div class="social-links">
                        <ul>
                           <li>
                               <a title="Follow us on Facebook" target="_blank" href="https://www.facebook.com/Qalaxy-Labs-107489775111278"><i class="ri-facebook-circle-line"></i></a>
                           </li>
                           <li>
                               <a title="Follow us on Instagram" target="_blank" href="https://www.instagram.com/qalaxylabs/"><i class="ri-instagram-line"></i></a>
                           </li>
                           <li>
                               <a title="Follow us on Twitter" target="_blank" href="https://lnkd.in/ejhruvNP"><i class="ri-twitter-x-line"></i></a>
                           </li>
                           <li>
                               <a title="Follow us on Youtube" target="_blank" href="https://lnkd.in/ef_7KU43"><i class="ri-youtube-line"></i></a>
                           </li>
                           <li>
                               <a title="Follow us on Pinterest" target="_blank" href="https://lnkd.in/gf5j9Gtm"><i class="ri-pinterest-line"></i></a>
                           </li>
                       </ul>
                   </div>
                        <div class="footer-links">
                            <ul>
                                <li><a href="">FAQ</a></li>
                                <li><a href="">Policy</a></li>
                                <li><a href="">Whitepaper</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
            {/* <!-- footer-closed--> */}
        </div>
        {/* <!-- meta-mask-modal-start--> */}
        <div class="meta-mask modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="modal-body">
                        <div class="comman-content-meta">
                            <h3>Hey there</h3>
                            <span>you can</span>
                            <h3>connect to wallet</h3>
                            <span>now</span>
                        </div>
                        <div class="select-meta-box">
                            <div class="select-box-comman">
                                <select class="form-select" aria-label="Default select example">
                                    <option selected>metamask</option>
                                    <option value="1">Wallet options</option>
                                </select>
                            </div>
                            <button>confirm</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
        // <div class="wrap">
        //     <div class="content content-fluid">
        //         <div class="container-xl wide-xl" >
        //             <div class="content-body" >
        //                 {/* <!-- head --> */}
        //                 <div class="block-head block-head-sm">
        //                     <div class="d-flex align-items-center justify-content-between">
        //                         <div class="block-head-content">
        //                             <h3 class=" text-white">
                                      
        //                                 {(location.pathname.substring(1).toUpperCase())}
        //                             </h3>
        //                         </div>
        //                     </div>
        //                 </div>



        //                 <div class="coming_soon py-5">
        //                     <div class="row justify-content-center  mt-5" >
        //                         <div class="col-lg-8 text-center">
        //                             <img src="assets/img/comingsoon.svg" class="w-100 img-fluid" alt="" />
        //                             <Link to="/" class="btn btn-primary btn_gradient mt-4 mt-md-5"> Back to Home </Link>
        //                         </div>
        //                     </div>
        //                 </div>


        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}

export default ComingSoon