import axios from "axios";
import { alertErrorMessage } from "../CustomComponents/CustomAlertMessage";
import LoaderHelper from "../CustomComponents/Loading/LoaderHelper";

export const ApiCallGet = async (url, headers) => {
    try {
        const response = await axios.get(url, { headers: headers });
        LoaderHelper.loaderStatus(false);
        return response?.data;
    } catch (error) {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(error?.response?.data?.message)
    }
};
