import ComingSoon from "../UiComponents/ComingSoon"
import Dashboard from "../UiComponents/Dashboard"
import Staking from "../UiComponents/Staking"
import LandingPage from "../UiComponents/LandingPage"
import SwapingBuySystemforMe from "../UiComponents/SwapingBuySystemforMe"
import { Routes, Route, useLocation } from 'react-router-dom';
import TransactionHistory from "../UiComponents/TransactionHistory"
import Header from "../CustomComponents/Header"
import Sidebar from "../CustomComponents/Sidebar"
import QalaxyLandingPage from "../UiComponents/QalaxyLandingPage"
import About from "../UiComponents/About"
import QalToken from "../UiComponents/QalToken"
import RoboTrdr from "../UiComponents/RoboTrdr"
import SmartStaking from "../UiComponents/SmartStaking"
import Community from "../UiComponents/Community"
import QalaxyHeader from "../CustomComponents/QalaxyHeader"
import HowStakingWorksQealth from "../UiComponents/HowStakingWorksQealth"
import Qalverse from "../UiComponents/Qalverse"
import Qealth from "../UiComponents/Qealth"
import Tayc from "../UiComponents/Tayc"
import { ToastContainer } from "react-toastify"



const Routing = () => {
    const location = useLocation();

    return (
        <>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss={false} draggable pauseOnHover limit={1} theme="dark" />
            {((location.pathname == "/dashboard") || (location.pathname == "/staking") || (location.pathname == "/swap") || (location.pathname == "/transactions")
            )
                ? <><Header /> <Sidebar /></> : <QalaxyHeader />}
            <Routes>
                <Route path="/" element={<QalaxyLandingPage />} />
                <Route path="/quaniToken" element={<LandingPage />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/staking" element={<Staking />} />
                <Route path="/swap" element={<SwapingBuySystemforMe />} />
                <Route path="/transactions" element={<TransactionHistory />} />
                <Route path="/about" element={<About />} />
                <Route path="/qalToken" element={<QalToken />} />
                <Route path="/qalverse" element={<Qalverse />} />
                <Route path="/qealth" element={<Qealth />} />
                <Route path="/roboTrdr" element={<RoboTrdr />} />
                <Route path="/tayc" element={<Tayc />} />
                <Route path="/smartStaking" element={<SmartStaking />} />
                <Route path="/how-staking-works-qealth" element={<HowStakingWorksQealth />} />
                <Route path="/community" element={<Community />} />
                <Route path="*" element={<ComingSoon />} />
            </Routes>
        </>

    )
}

export default Routing