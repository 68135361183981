import React, { useState } from "react";

const QalaxyFooter = () => {

    return (
        <footer>
                <div class="container">
                    <div class="footer-logo">
                        <a href=""><img src="qalaxyAssets/img/logo.png" alt="" /></a>
                        {/* <p>
                            Qalaxy Labs is a project to take people into virtual QALVERSE, it will be launched once the community gets stronger and its 1st NFT series is minted over Polygon Matic. As the NFT once sold and owners when stake
                            that NFT’s will Qalaxy Labs the owner of the NFT will get the opportunity to earn native token (Polygon Matic) QAL.
                        </p> */}
                    </div>
                    <div class="contect-footer-inner">
                        {/* <h4>Contact Us</h4> */}
                        {/* <a href="mailto:support@qalaxylabs.com">support@qalaxylabs.com</a> */}
                        <div class="social-links">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/Qalaxy-Labs-107489775111278"><i class="ri-facebook-circle-line"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/qalaxylabs/"><i class="ri-instagram-line"></i></a>
                                </li>
                                <li>
                                    <a href="https://lnkd.in/ejhruvNP"><i class="ri-twitter-x-line"></i></a>
                                </li>
                                <li>
                                    <a href="https://lnkd.in/ef_7KU43"><i class="ri-linkedin-line"></i></a>
                                </li>
                                <li>
                                    <a href="https://lnkd.in/gf5j9Gtm"><i class="ri-pinterest-line"></i></a>
                                </li>
                            </ul>
                        </div>
                        <div class="footer-links">
                            <ul>
                                <li><a href="">FAQ</a></li>
                                <li><a href="">Policy</a></li>
                                <li><a href="">Whitepaper</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
    );
};

export default QalaxyFooter;
