import React from "react";
import { Link } from "react-router-dom";

const QalaxyHeader = () => {



    const handleCloseSidebar = () => {
        let sidebar = document.getElementById("navbarSupportedContent");
        if (sidebar.classList.contains('show')) {
            sidebar.classList.remove('show')
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });
        }
    };

    return (
        <header>
            <div class="container">
                <nav class="navbar navbar-expand-lg">
                    <Link class="navbar-brand" to="/"><img src="qalaxyAssets/img/logo.png" alt="logo" /></Link>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 3v2H3V3h9zm4 16v2H3v-2h13zm6-8v2H3v-2h19z" />
                        </svg>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav mx-auto">
                            <li class="nav-item" onClick={handleCloseSidebar}>
                                <Link class="nav-link" to="/">Home</Link>
                            </li>
                            {/* <li class="nav-item">
                            <Link class="nav-link" to="/quaniToken">Quani Token</Link>
                        </li> */}
                            <li class="nav-item" onClick={handleCloseSidebar}>
                                <Link class="nav-link" to="/about">About us</Link>
                            </li>
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle d-flex-between" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Qalvarse
                                    <i class="ri-arrow-down-s-line"></i>
                                </a>
                                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <Link class="dropdown-item" to="/qalverse" onClick={handleCloseSidebar} >About Qalvarse </Link>
                                    <Link class="dropdown-item" to="/tayc" onClick={handleCloseSidebar}>TAYC   </Link>
                                    <Link class="dropdown-item" to="/qalToken" onClick={handleCloseSidebar}>Qal Token</Link>
                                    {/*  <Link class="dropdown-item" to="/robotrdr">Robo TRDT</Link>
                                <Link class="dropdown-item" to="coming-soon.html">Qalverse</Link> */}
                                </div>
                            </li>

                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle d-flex-between" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Qealth
                                    <i class="ri-arrow-down-s-line"></i>
                                </a>
                                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <Link class="dropdown-item" to="/Qealth" onClick={handleCloseSidebar}>About Qealth </Link>
                                    <Link class="dropdown-item" to="/roboTrdr" onClick={handleCloseSidebar}> Robo TRDR  </Link>
                                    <Link class="dropdown-item" to="/quaniToken" onClick={handleCloseSidebar}>Quani Token</Link>
                                </div>
                            </li>

                            <li class="nav-item" onClick={handleCloseSidebar}>
                                <Link class="nav-link" to="/how-staking-works-qealth">Staking  </Link>
                            </li>
                            {/* <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle d-flex-between" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Staking
                                    <i class="ri-arrow-down-s-line"></i>
                                </a>
                                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <Link class="dropdown-item" to="/how-staking-works-qealth" onClick={handleCloseSidebar}>How Staking Works Qealth </Link>
                                    <Link class="dropdown-item" to="/dashboard" onClick={handleCloseSidebar}> Connect Wallet    </Link>
                                </div>
                            </li> */}

                            <li class="nav-item">
                                <Link class="nav-link" to="/community" onClick={handleCloseSidebar}>Community </Link>
                            </li>
                            {/* <li class="nav-item">
                            <Link class="nav-link" to="/smartStaking">Smart Staking </Link>
                        </li> */}
                            {/* <li class="nav-item">
                            <a class="nav-link" href="qalaxyAssets/img/Whitepaper.pdf">White Paper </a>
                        </li> */}
                        </ul>
                        <div class="right-bar">
                            <Link to="/dashboard" class="btn btn_gradient btn-round d-flex align-items-center">Dashboard <img src="assets/img/arrow_right.svg" class="ms-3" alt="" />  </Link>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    );
};

export default QalaxyHeader;
