import React from 'react'
import { useLocation } from "react-router-dom"
import { init, useConnectWallet } from '@web3-onboard/react'
import { ethers } from 'ethers'
import contract from "../UiComponents/Contract.json"

const HeaderNavbar = () => {

    const location = useLocation();
    const tokenAddress = contract.Quaniaddress;
    const { ethereum } = window;
    const [{ wallet, connecting }, connect, disconnect] = useConnectWallet()
    let ethersProvider

    if (wallet) {
        ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any')
    }
    const addTokenFunction = async () => {
        try {

            const wasAdded = await ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20',
                    options: {
                        address: tokenAddress,
                        symbol: "QUANI",
                        decimals: 18,
                        image: "assets/img/logo.png"
                    },
                },
            });

            if (wasAdded) {
                console.log('Thanks for your interest!');
            } else {
                console.log('Quani Coin has not been added');
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (

        <div class="block-head block-head-sm">
            <div class="d-flex align-items-center justify-content-between">
                <div class="block-head-content d-flex flex-column">
                    <h3 class=" text-white">

                        {location.pathname === '/swap' ? "BUY" : (location.pathname.substring(1).toUpperCase())}

                    </h3>
                    <small>  {wallet && <span >{wallet?.accounts[0]?.address} </span>}</small>
                </div>
                <div className='btn_row' >
                {/* <span> Add Quani to Wallet   </span> */}
                <button class="btn btn_gradient btn-sm btn-icon" onClick={addTokenFunction} > <img src="/assets/img/add_wallet.svg"  className='img-fluid' />  <span className='ms-2' >  Add Quani to Wallet   </span> </button>
                <button class="btn btn_gradient" disabled={connecting} onClick={() => (wallet ? disconnect(wallet) : connect())}>
                    {connecting ? 'Connecting' : wallet ? `Disconnect ` : 'Connect Wallet'}
                </button>
                </div>
                {/* <button class="btn btn_gradient"><em class="icon ni ni-reports"></em><span>Connect Wallet</span> </button> */}

            </div>
        </div>

    )
}


export default HeaderNavbar