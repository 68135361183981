import { useState, useEffect } from "react";
import LoaderHelper from "../CustomComponents/Loading/LoaderHelper";
import HeaderNavbar from "./HeaderNavbar";
import Sidebar from "../CustomComponents/Sidebar";
import { init, useConnectWallet } from "@web3-onboard/react";
import { ethers } from "ethers";

import tokenData from "./Contract.json";
import stakingData from "./StakingContract.json";
import { alertSuccessMessage } from "../CustomComponents/CustomAlertMessage";

const Staking = () => {
  const [unStakeToken, setUnStakeToken] = useState();
  const [stakeToken, setStakeToken] = useState();
  const [claimToken, setClaimToken] = useState();
  const [USERTotalQuaniBalance, setUSERTotalQuaniBalance] = useState();
  const [userStakedToken, setUserStakedToken] = useState();
  const [userClaimableTokens, setUserClaimableTokens] = useState();
  const [percentageStakeOptionStake, setpercentageStakeOptionStake] =
    useState("");
  const [percentageStake, setpercentageStake] = useState(false);
  const [percentageStakeOptionUnStake, setpercentageStakeOptionUnStake] =
    useState("");
  const [percentageUnstake, setPercentageUnstake] = useState(false);
  const [percentageStakeOptionClaim, setPercentageStakeOptionClaim] =
    useState("");
  const [percentageClaimable, setPercentageClaimable] = useState(false);

  const PRICE_OF_QUANI = 0.3;

  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();

  let ethersProvider;
  let signer;
  let userAddress;

  const stakeTokens = async () => {
    try {
      if (!wallet) {
        connect();
      }
      ethersProvider = new ethers.providers.Web3Provider(
        wallet.provider,
        "any"
      );
      signer = ethersProvider.getSigner();
      const QuaniStakingABI = stakingData.abi;
      const QuaniStakingAddress = stakingData.address;
      const stakingInstance = new ethers.Contract(
        QuaniStakingAddress,
        QuaniStakingABI,
        signer
      );
      const QuaniAddress = tokenData.Quaniaddress;
      const QuaniABI = tokenData.abi;
      const quaniInstance = new ethers.Contract(QuaniAddress, QuaniABI, signer);

      const amountToStake = stakeToken;
      const amountToStakeInWei = ethers.utils.parseEther(
        amountToStake.toString()
      );

      try {
        LoaderHelper.loaderStatus(true);
        await quaniInstance.approve(QuaniStakingAddress, amountToStakeInWei);
        await stakingInstance.stakeTokens(amountToStakeInWei);
        LoaderHelper.loaderStatus(false);
        alertSuccessMessage(`Congratulations !! Your ${stakeToken} QUANI have been staked successfully you will be rewarded 36% APY on your staked token`)
      } catch (error) {
        alert(error.message);
        LoaderHelper.loaderStatus(false);
      }
      setStakeToken("");
    } catch (error) {
      console.log("Error", error.message);
      LoaderHelper.loaderStatus(false);
    }
  };

  const unStakeTokens = async () => {
    try {
      if (!wallet) {
        connect();
      }
      // console.log("Inside Unstaking..")
      ethersProvider = new ethers.providers.Web3Provider(
        wallet.provider,
        "any"
      );
      signer = ethersProvider.getSigner();

      const QuaniStakingAddress = stakingData.address;
      const QuaniStakingABI = stakingData.abi;
      const stakingInstance = new ethers.Contract(
        QuaniStakingAddress,
        QuaniStakingABI,
        signer
      );

      const amountToUnstake = unStakeToken;
      const amountToUnstakeInWei = ethers.utils.parseEther(
        amountToUnstake.toString()
      );
      LoaderHelper.loaderStatus(true);
      await stakingInstance.unStakeTokens(amountToUnstakeInWei);
      LoaderHelper.loaderStatus(false);
      setUnStakeToken(" ");
    } catch (err) {
      console.log("error", err.message);
    }
  };

  const claimRewards = async () => {
    try {
      if (!wallet) {
        connect();
      }
      ethersProvider = new ethers.providers.Web3Provider(
        wallet.provider,
        "any"
      );
      signer = ethersProvider.getSigner();
      const QuaniAddress = tokenData.Quaniaddress;
      const QuaniABI = tokenData.abi;
      const QuaniStakingAddress = stakingData.address;
      const QuaniStakingABI = stakingData.abi;
      const stakingInstance = new ethers.Contract(
        QuaniStakingAddress,
        QuaniStakingABI,
        signer
      );
      const amountToClaim = claimToken;
      const amountToClaimInWei = ethers.utils.parseEther(
        amountToClaim.toString()
      );
      LoaderHelper.loaderStatus(true);
      await stakingInstance.claimRewards(amountToClaimInWei);
      LoaderHelper.loaderStatus(false);
      setClaimToken(" ");
    } catch (error) {}
  };

  const updaterewards = async () => {
    try {
      ethersProvider = new ethers.providers.Web3Provider(
        wallet.provider,
        "any"
      );
      signer = ethersProvider.getSigner();
      const QuaniStakingAddress = stakingData.address;
      const QuaniStakingABI = stakingData.abi;
      const stakingInstance = new ethers.Contract(
        QuaniStakingAddress,
        QuaniStakingABI,
        signer
      );
      LoaderHelper.loaderStatus(true);
      await stakingInstance.calcuateRewards(wallet.accounts[0].address);
      const userStakedTokens = await stakingInstance.AddressToStakingDetails(
        wallet.accounts[0].address
      );
      const finalClaimableTokens = ethers.utils.formatUnits(
        userStakedTokens.claimableRewards
      );
      let resultString = finalClaimableTokens.toString().slice(0, 8);
      if (resultString > 0) {
        setUserClaimableTokens(resultString);
      } else setUserClaimableTokens("0.0");
      LoaderHelper.loaderStatus(false);
    } catch (err) {
      LoaderHelper.loaderStatus(false);
      console.log("error", err.message);
    }
  };

  useEffect(() => {
    if (!wallet) {
      connect();
    } 
    else if (wallet) 
    {
      ethersProvider = new ethers.providers.Web3Provider(
        wallet.provider,
        "any"
      );
      signer = ethersProvider.getSigner();
      userAddress = wallet.accounts[0];
      let USERADDRESS = userAddress.address;
      const fetchData = async () => {
        try {
          const QuaniAddress = tokenData.Quaniaddress;
          const QuaniABI = tokenData.abi;
          const QuaniStakingAddress = stakingData.address;
          const QuaniStakingABI = stakingData.abi;
          const quaniInstance = new ethers.Contract(
            QuaniAddress,
            QuaniABI,
            signer
          );
          const stakingInstance = new ethers.Contract(
            QuaniStakingAddress,
            QuaniStakingABI,
            signer
          );
          const userTokenBalance = await quaniInstance.balanceOf(USERADDRESS);
          const formattedBalance = ethers.utils.formatUnits(userTokenBalance);
          setUSERTotalQuaniBalance(formattedBalance);
          const userStakedTokens =
            await stakingInstance.AddressToStakingDetails(USERADDRESS);
          const finalTotalStakedTokens = ethers.utils.formatUnits(
            userStakedTokens.stakedAmount
          );
          setUserStakedToken(finalTotalStakedTokens);
          const finalClaimableTokens = ethers.utils.formatUnits(
            userStakedTokens.claimableRewards
          );
          let resultString = finalClaimableTokens.toString().slice(0, 8);
          setUserClaimableTokens(resultString);
        } catch (error) {
          console.error("Error fetching data:", error.message);
        }
      };
      fetchData();
    }
  }, [USERTotalQuaniBalance, userStakedToken, userClaimableTokens, wallet]);

  return (
    <>
      {/* <Sidebar/> */}
      <div class="wrap">
        <div class="content content-fluid">
          <div class="container-xl wide-xl">
            <div class="content-body">
              <HeaderNavbar />
              <div class="row">
                <div class="col-lg-4">
                  {/* <div class="card-title">
                    <h6 class="title">Active Campaign</h6>
                  </div> */}

                  <div class="card-bodys c_bg_1 card-body_img ">
                    <div className="d-flex align-items-center justify-content-between">
                      <img
                        src="assets/img/ri_1.png"
                        width="50"
                        alt=""
                        class="img-fluid"
                      />
                      <h6 className="text-dark">
                        {" "}
                        <b>
                          $
                          {USERTotalQuaniBalance == undefined
                            ? 0
                            : USERTotalQuaniBalance * PRICE_OF_QUANI}
                        </b>{" "}
                      </h6>
                    </div>
                    <span>Your Quani Balance</span>
                    <h4>{USERTotalQuaniBalance}</h4>
                  </div>

                  <div class="card-bodys c_bg_2   card-body_img ">
                    <div className="d-flex align-items-center justify-content-between">
                      <img
                        src="assets/img/ri2.png"
                        width="50"
                        alt=""
                        class="img-fluid"
                      />
                      <h6 className="text-dark">
                        {" "}
                        <b>
                          $
                          {userStakedToken == undefined
                            ? 0
                            : userStakedToken * PRICE_OF_QUANI}
                        </b>{" "}
                      </h6>
                    </div>
                    <span>Your Token Staked</span>
                    <h4>{userStakedToken}</h4>
                  </div>

                  <div class="card-bodys c_bg_3  mb-0 card-body_img">
                    <div className="d-flex align-items-center justify-content-between">
                      <img
                        src="assets/img/ri3.png"
                        width="50"
                        alt=""
                        class="img-fluid"
                      />
                      <h6 className="text-dark">
                        {" "}
                        <b>
                          $
                          {userClaimableTokens == undefined
                            ? 0
                            : userClaimableTokens * PRICE_OF_QUANI}
                        </b>{" "}
                      </h6>
                    </div>
                    <span className="d-flex align-items-center justify-content-between">
                      {" "}
                      Your Claimable Reward
                      <button
                        type="button"
                        className="btn refresh_btn"
                        onClick={() => updaterewards()}
                      >
                        {" "}
                        Refresh{""}
                      </button>
                    </span>
                    <h4 className="mb-0"> {userClaimableTokens}</h4>
                    <span className="my-0">
                      <small className="text-dark">
                        Note refreshing cost GAS Fee
                      </small>
                    </span>
                  </div>
                </div>

                <div class="col-lg-8">
                  <div class="card  h-100 ">
                    <div class="card-body">
                      <ul
                        class="nav nav-pills q_tabs  react-tabs__tab-list  mobile_scroll"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li class="nav-item " role="presentation">
                          <button
                            class="nav-link active"
                            id="pills-Stake-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-Stake"
                            type="button"
                            role="tab"
                            aria-controls="pills-Stake"
                            aria-selected="true"
                          >
                            Stake
                          </button>
                        </li>
                        <li class="nav-item  " role="presentation">
                          <button
                            class="nav-link "
                            id="pills-Unstake-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-Unstake"
                            type="button"
                            role="tab"
                            aria-controls="pills-Unstake"
                            aria-selected="false"
                          >
                            Unstake
                          </button>
                        </li>
                        <li class="nav-item " role="presentation">
                          <button
                            class="nav-link "
                            id="pills-Claim-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-Claim"
                            type="button"
                            role="tab"
                            aria-controls="pills-Claim"
                            aria-selected="false"
                          >
                            Claim Reward
                          </button>
                        </li>
                      </ul>
                      <div class="tab-content pt-2" id="pills-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="pills-Stake"
                          role="tabpanel"
                          aria-labelledby="pills-Stake-tab"
                        >
                          <form>
                            <div class="form-group  ">
                              <label>Stake Token</label>
                              <input
                                type="number"
                                class="form-control"
                                placeholder="Enter Amount"
                                value={stakeToken}
                                onWheel={(e) => e.target.blur()}
                                onChange={(e) => {
                                  setStakeToken(e.target.value);
                                  setpercentageStake(false);
                                  setpercentageStakeOptionStake("");
                                }}
                              />

                              


                            </div>
                            <div className="row g-2 mb-4" >

                              <div className="col-3" >

                              <button
                                className={`btn btn_gradient btn-sm  w-100 ${
                                  percentageStakeOptionStake === 25 && "active"
                                }`}
                                style={{
                                  padding: "0px 12px",
                                  marginRight: "4px",
                                }}
                                type="button"
                                onClick={() => {
                                  try {
                                    if (!wallet) {
                                      connect();
                                    } else {
                                      setpercentageStakeOptionStake(25);
                                      setStakeToken(
                                        USERTotalQuaniBalance == undefined
                                          ? 0
                                          : USERTotalQuaniBalance * 0.25
                                      );
                                      setpercentageStake(true);
                                    }
                                  } catch (error) {
                                    alert("Error");
                                  }
                                }}
                              >
                                25%
                              </button>

                              </div>
                              <div className="col-3" >


                              <button
                                className={`btn btn_gradient btn-sm  w-100 ${
                                  percentageStakeOptionStake === 50 && "active"
                                }`}
                                style={{
                                  padding: "0px 12px",
                                  marginRight: "4px",
                                }}
                                type="button"
                                onClick={() => {
                                  try {
                                    if (!wallet) {
                                      connect();
                                    } else {
                                      setpercentageStakeOptionStake(50);
                                      setStakeToken(
                                        USERTotalQuaniBalance == undefined
                                          ? 0
                                          : USERTotalQuaniBalance * 0.5
                                      );
                                      setpercentageStake(true);
                                    }
                                  } catch (error) {
                                    alert("Error");
                                  }
                                }}
                              >
                                50%
                              </button>

                              </div>
                              <div className="col-3" >

                              <button
                                className={`btn btn_gradient btn-sm  w-100 ${
                                  percentageStakeOptionStake === 75 && "active"
                                }`}
                                style={{
                                  padding: "0px 12px",
                                  marginRight: "4px",
                                }}
                                type="button"
                                onClick={() => {
                                  try {
                                    if (!wallet) {
                                      connect();
                                    } else {
                                      setpercentageStakeOptionStake(75);
                                      setStakeToken(
                                        USERTotalQuaniBalance == undefined
                                          ? 0
                                          : USERTotalQuaniBalance * 0.75
                                      );
                                      setpercentageStake(true);
                                    }
                                  } catch (error) {
                                    alert("Error");
                                  }
                                }}
                              >
                                75%
                              </button>

                              </div>
                              <div className="col-3" >

                              <button
                                className={`btn btn_gradient btn-sm  w-100 ${
                                  percentageStakeOptionStake === 100 && "active"
                                }`}
                                style={{
                                  padding: "0px 12px",
                                  marginRight: "4px",
                                }}
                                type="button"
                                onClick={() => {
                                  try {
                                    if (!wallet) {
                                      connect();
                                    } else {
                                      setpercentageStakeOptionStake(100);
                                      setStakeToken(
                                        USERTotalQuaniBalance == undefined
                                          ? 0
                                          : USERTotalQuaniBalance * 1
                                      );
                                      setpercentageStake(true);
                                    }
                                  } catch (error) {
                                    alert("Error");
                                  }
                                }}
                              >
                                100%
                              </button>

                              </div>
                              </div>
                              
                            <div class="form-group mb-0 pt-2">
                              <button
                                class="btn btn-primary btn_gradient btn-block w-100 "
                                type="button"
                                onClick={() => stakeTokens()}
                                disabled={!stakeToken}
                              >
                                {" "}
                                Stake{" "}
                              </button>
                            </div>
                          </form>
                        </div>

                        <div
                          class="tab-pane fade show"
                          id="pills-Unstake"
                          role="tabpanel"
                          aria-labelledby="pills-Unstake-tab"
                        >
                          <form>
                            <div class="form-group  ">
                              <label>Unstake Quani Tokens </label>
                              <input
                                type="number"
                                class="form-control"
                                placeholder="Enter Amount"
                                value={unStakeToken}
                                onChange={(e) =>
                                  setUnStakeToken(e.target.value)
                                }
                                onWheel={(e) => e.target.blur()}
                              />
                              
                            </div>

                            <div className="row g-2 mb-4" >

<div className="col-3" >
                              <button
                                className={`btn btn_gradient btn-sm  w-100 ${
                                  percentageStakeOptionUnStake === 25 &&
                                  "active"
                                }`}
                                style={{
                                  padding: "0px 12px",
                                  marginRight: "4px",
                                }}
                                type="button"
                                onClick={() => {
                                  try {
                                    if (!wallet) {
                                      connect();
                                    } else {
                                      setpercentageStakeOptionUnStake(25);
                                      setUnStakeToken(
                                        userStakedToken == undefined
                                          ? 0
                                          : userStakedToken * 0.25
                                      );
                                      setPercentageUnstake(true);
                                    }
                                  } catch (error) {
                                    alert("Error");
                                  }
                                }}
                              >
                                25%
                              </button>{" "}
                                </div>
                              <div className="col-3" >

                              <button
                                className={`btn btn_gradient btn-sm  w-100 ${
                                  percentageStakeOptionUnStake === 50 &&
                                  "active"
                                }`}
                                style={{
                                  padding: "0px 12px",
                                  marginRight: "4px",
                                }}
                                type="button"
                                onClick={() => {
                                  try {
                                    if (!wallet) {
                                      connect();
                                    } else {
                                      setpercentageStakeOptionUnStake(50);
                                      setUnStakeToken(
                                        userStakedToken == undefined
                                          ? 0
                                          : userStakedToken * 0.5
                                      );
                                      setPercentageUnstake(true);
                                    }
                                  } catch (error) {
                                    alert("Error");
                                  }
                                }}
                              >
                                50%
                              </button>{" "}

                              </div>
                              <div className="col-3" >

                              <button
                                className={`btn btn_gradient btn-sm  w-100 ${
                                  percentageStakeOptionUnStake === 75 &&
                                  "active"
                                }`}
                                style={{
                                  padding: "0px 12px",
                                  marginRight: "4px",
                                }}
                                type="button"
                                onClick={() => {
                                  try {
                                    if (!wallet) {
                                      connect();
                                    } else {
                                      setpercentageStakeOptionUnStake(75);
                                      setUnStakeToken(
                                        userStakedToken == undefined
                                          ? 0
                                          : userStakedToken * 0.75
                                      );
                                      setPercentageUnstake(true);
                                    }
                                  } catch (error) {
                                    alert("Error");
                                  }
                                }}
                              >
                                75%
                              </button>

</div>
<div className="col-3" >

                              
                              <button
                                className={`btn btn_gradient btn-sm  w-100 ${
                                  percentageStakeOptionUnStake === 100 &&
                                  "active"
                                }`}
                                style={{
                                  padding: "0px 12px",
                                  marginRight: "4px",
                                }}
                                type="button"
                                onClick={() => {
                                  try {
                                    if (!wallet) {
                                      connect();
                                    } else {
                                      setpercentageStakeOptionUnStake(100);
                                      setUnStakeToken(
                                        userStakedToken == undefined
                                          ? 0
                                          : userStakedToken * 1
                                      );
                                      setPercentageUnstake(true);
                                    }
                                  } catch (error) {
                                    alert("Error");
                                  }
                                }}
                              >
                                100%
                              </button>

</div>

                            </div>


                            <div class="form-group mb-0 pt-2">

                              <button
                                class="btn btn-primary btn_gradient btn-block w-100"
                                type="button"
                                onClick={() => {
                                  unStakeTokens();
                                  setpercentageStakeOptionUnStake(false);
                                  setPercentageUnstake("");
                                }}
                                disabled={!unStakeToken}
                              >
                                {" "}
                                Unstake{" "}
                              </button>
                            </div>
                          </form>
                        </div>

                        <div
                          class="tab-pane fade show"
                          id="pills-Claim"
                          role="tabpanel"
                          aria-labelledby="pills-Claim-tab"
                        >
                          <form>
                            <div class="form-group  ">
                              <div className="d-flex align-items-center justify-content-between label_row">
                                <label> Claim Reward </label>
                              </div>
                              <input
                                type="number"
                                class="form-control"
                                placeholder="Enter Amount"
                                value={claimToken}
                                onChange={(e) => setClaimToken(e.target.value)}
                                onWheel={(e) => e.target.blur()}        
                              />

                              
                            </div>



<div className="row g-2 mb-4" >

<div className="col-3" >
                              <button
                                className={`btn btn_gradient btn-sm  w-100 ${
                                  percentageStakeOptionClaim === 25 &&
                                  "active"
                                }`}
                                style={{
                                  padding: "0px 12px",
                                  marginRight: "4px",
                                }}
                                type="button"
                                onClick={() => {
                                  try {
                                    if (!wallet) {
                                      connect();
                                    } else {
                                      setPercentageStakeOptionClaim(25);
                                      setClaimToken(
                                        userClaimableTokens == undefined
                                          ? 0
                                          : userClaimableTokens * 0.25
                                      );
                                      setPercentageClaimable(true);
                                    }
                                  } catch (error) {
                                    alert("Error");
                                  }
                                }}
                              >
                                25%
                              </button>
                              </div>

<div className="col-3" >
                              <button
                                className={`btn btn_gradient btn-sm  w-100 ${
                                  percentageStakeOptionClaim === 50 &&
                                  "active"
                                }`}
                                style={{
                                  padding: "0px 12px",
                                  marginRight: "4px",
                                }}
                                type="button"
                                onClick={() => {
                                  try {
                                    if (!wallet) {
                                      connect();
                                    } else {
                                      setPercentageStakeOptionClaim(50);
                                      setClaimToken(
                                        userClaimableTokens == undefined
                                          ? 0
                                          : userClaimableTokens * 0.50
                                      );
                                      setPercentageClaimable(true);
                                    }
                                  } catch (error) {
                                    alert("Error");
                                  }
                                }}
                              >
                                50%
                              </button>


                              </div>

<div className="col-3" >
                              <button
                                className={`btn btn_gradient btn-sm  w-100 ${
                                  percentageStakeOptionClaim === 75 &&
                                  "active"
                                }`}
                                style={{
                                  padding: "0px 12px",
                                  marginRight: "4px",
                                }}
                                type="button"
                                onClick={() => {
                                  try {
                                    if (!wallet) {
                                      connect();
                                    } else {
                                      setPercentageStakeOptionClaim(75);
                                      setClaimToken(
                                        userClaimableTokens == undefined
                                          ? 0
                                          : userClaimableTokens * 0.75
                                      );
                                      setPercentageClaimable(true);
                                    }
                                  } catch (error) {
                                    alert("Error");
                                  }
                                }}
                              >
                                75%
                              </button>


                              </div>

<div className="col-3" >
                              <button
                                className={`btn btn_gradient btn-sm  w-100 ${
                                  percentageStakeOptionClaim === 100 &&
                                  "active"
                                }`}
                                style={{
                                  padding: "0px 12px",
                                  marginRight: "4px",
                                }}
                                type="button"
                                onClick={() => {
                                  try {
                                    if (!wallet) {
                                      connect();
                                    } else {
                                      setPercentageStakeOptionClaim(100);
                                      setClaimToken(
                                        userClaimableTokens == undefined
                                          ? 0
                                          : userClaimableTokens * 1
                                      );
                                      setPercentageClaimable(true);
                                    }
                                  } catch (error) {
                                    alert("Error");
                                  }
                                }}
                              >
                                100%
                              </button>

                              </div>

</div>

                            <div class="form-group mb-0 pt-2">
                              <button
                                class="btn btn-primary btn_gradient btn-block w-100"
                                type="button"
                                onClick={() => claimRewards()}
                                disabled={!claimToken}
                              >
                                {" "}
                                Claim{" "}
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Staking;
