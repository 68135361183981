import QalaxyFooter from "../CustomComponents/QalaxyFooter"
import QalaxyHeader from "../CustomComponents/QalaxyHeader"

const Qealth = () =>{
    return(
        <>
        <div class="site-content">
  {/* <!-- header-start--> */}
         {/* <QalaxyHeader/> */}
            {/* {/* <!-- header-closed--> */}

            {/* <!--- feature-slider-wrapper-start--> */}
            <section class="fature-slider-wrapper qal-token full-height-box-vh">
                <div class="start">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div class="container">
                    <span class="qalone"><img src="qalaxyAssets/img/gold1.png" alt="" /> </span>
                    <span class="qaltwo"><img src="qalaxyAssets/img/gold2.png" alt="" /> </span>
                    <span class="qalthree"><img src="qalaxyAssets/img/gold3.png" alt="" /> </span>
                    <span class="qaltfour"><img src="qalaxyAssets/img/gold4.png" alt="" /> </span>

                    <figure><img src="qalaxyAssets/img/round.png" alt="" /></figure>
                    <div class="slider-content-inner">
                        <h1>Financial Growth with QEALTH </h1>
                        <p>
                           "QEALTH by Qalaxy Labs is where cutting-edge technology meets your finances. Our AI-driven platform aims to offer investors high-yield opportunities, transforming how you grow your assets."
                        </p>
                    </div>
                </div>
            </section>

            {/* <!--- feature-slider-wrapper-closed--> */}
            <section class="comman-vectors-wrapper full-height-box-vh">
                <div class="container">
                    <div class="row align-items-center flex-sm-column-reverses">
                        <div class="col-md-6">
                            <div class="comman-vector-content">
                                <h2>Innovative Trading with roboTRDR </h2>
                                <h3>Navigate the crypto market with roboTRDR, our advanced AI that analyzes trends and executes trades, designed to maximize returns and minimize risks.</h3>
                             
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="vector-shapes text-center">
                                <img src="qalaxyAssets/img/money-tree.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="comman-vectors-wrapper full-height-box-vh">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <div class="vector-shapes text-center">
                                <img src="qalaxyAssets/img/crosswalk.png" alt="" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="comman-vector-content">
                                <h3>Invest with confidence</h3>
                            <h3>
                                   Secure and Intelligent Investing<br />
                                 QEALTH provides a secure environment
                                </h3>

                                <p>Paired with intelligent algorithms, ensuring your portfolio is optimized for the best possible outcomes. QEALTH's platform is built for accessibility, offering an intuitive experience for both seasoned investors and those new to crypto investing.</p>
                             
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- footer-start--> */}
           <QalaxyFooter/>
            {/* <!-- footer-closed--> */}
        </div>
        {/* <!-- meta-mask-modal-start--> */}
        <div class="meta-mask modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="modal-body">
                        <div class="comman-content-meta">
                            <h3>Hey there</h3>
                            <span>you can</span>
                            <h3>connect to wallet</h3>
                            <span>now</span>
                        </div>
                        <div class="select-meta-box">
                            <div class="select-box-comman">
                                <select class="form-select" aria-label="Default select example">
                                    <option selected>metamask</option>
                                    <option value="1">Wallet options</option>
                                </select>
                            </div>
                            <button>confirm</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Qealth