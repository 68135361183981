import { useState, useEffect } from "react"
import LoaderHelper from "../CustomComponents/Loading/LoaderHelper"
import HeaderNavbar from "./HeaderNavbar"
import { useConnectWallet } from '@web3-onboard/react'
import tokenData from './Contract.json'
import usdtData from './UsdtContract.json'
import { ethers } from 'ethers'
import stakingData from "./StakingContract.json";
import adminData from "./adminDepoite.json"
import { alertErrorMessage, alertSuccessMessage } from "../CustomComponents/CustomAlertMessage"
import { ApiCallGet } from "../ApiModule/APICall"


const SwapingBuySystemforMe = () => {
    const [quaniToken, setQuaniToken] = useState()
    const [usdt, setUsdt] = useState()
    const [isReverse, setIsReverse] = useState(false)
    const [percentage, setPercentage] = useState(false)
    const [USERTotalQuaniBalance, setUSERTotalQuaniBalance] = useState()
    const [UserUSDTBalance, setUserUSDTBalance] = useState()
    const [{ wallet, connecting }, connect, disconnect] = useConnectWallet()
    const [percentageOption, setPercentageOption] = useState('');
    const [userClaimableTokens, setUserClaimableTokens] = useState();
    const [userStakedToken, setUserStakedToken] = useState();
    const [PRICE_OF_QUANI, setPRICE_OF_QUANI] = useState(0);


    // const PRICE_OF_QUANI = 0.3;
    const adminWallet = adminData.address;
    const USER_BALANCE_QUANI = USERTotalQuaniBalance;
    let ethersProvider;
    let signer;
    let userAddress;


    const fetchBuyPrice = async () => {
        LoaderHelper.loaderStatus(true)
        let url = "https://preprod.unicas.co.in/get-quani-price";
        const headers = {
          'Content-Type': 'application/json',
        };
        try {
          const result = await ApiCallGet(url, headers)
          setPRICE_OF_QUANI(result?.statics?.sell_rate?.toFixed(5))
          LoaderHelper.loaderStatus(false)
        } catch (error) {
          alertErrorMessage(error.message)
          LoaderHelper.loaderStatus(false)
        }
      };

    const BuyOrSwap = async () => {
        try {
            if (!wallet) {
                connect();
            }
            ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any')
            signer = ethersProvider.getSigner();
            const amountToStake = usdt;
            const amountToStakeInWei = ethers.utils.parseEther(amountToStake.toString());
            const USDT = usdtData.address;
            const usdtABI = usdtData.abi;
            const usdtInstance = new ethers.Contract(USDT, usdtABI, signer);
            LoaderHelper.loaderStatus(true);
            try {
                await usdtInstance.transfer(adminWallet, amountToStakeInWei);
                LoaderHelper.loaderStatus(false);
                alertSuccessMessage(`Thank you for your successfull transfer of USDT ${amountToStake} against purchase of QUANI ${quaniToken} you will receiving these QUANI on your BSC wallet address ${wallet.accounts[0].address}`)
            } catch (error) {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage('Transaction failed... something went wrong')
            }
            setUsdt(0);
            setQuaniToken(0)

        } catch (error) {
            alert(error.message);
        }
    };

    useEffect(() => {
        if (!wallet) {
            connect();
        } else if (wallet) {
            ethersProvider = new ethers.providers.Web3Provider(
                wallet.provider,
                "any"
            );
            signer = ethersProvider.getSigner();
            userAddress = wallet.accounts[0];
            let USERADDRESS = userAddress.address;
            const fetchData = async () => {
                try {
                    const QuaniAddress = tokenData.Quaniaddress;
                    const QuaniABI = tokenData.abi;
                    const QuaniStakingAddress = stakingData.address;
                    const QuaniStakingABI = stakingData.abi;
                    const quaniInstance = new ethers.Contract(
                        QuaniAddress,
                        QuaniABI,
                        signer
                    );
                    const stakingInstance = new ethers.Contract(
                        QuaniStakingAddress,
                        QuaniStakingABI,
                        signer
                    );

                    const userStakedTokens =
                        await stakingInstance.AddressToStakingDetails(USERADDRESS);
                    const finalTotalStakedTokens = ethers.utils.formatUnits(
                        userStakedTokens.stakedAmount
                    );
                    setUserStakedToken(finalTotalStakedTokens);
                    const finalClaimableTokens = ethers.utils.formatUnits(
                        userStakedTokens.claimableRewards
                    );
                    let resultString = finalClaimableTokens.toString().slice(0, 8);
                    setUserClaimableTokens(resultString);
                } catch (error) {
                    console.error("Error fetching data:", error.message);
                }
            };
            fetchData();
        }
    }, [userStakedToken, userClaimableTokens, wallet]);

    const updaterewards = async () => {
        try {
            ethersProvider = new ethers.providers.Web3Provider(
                wallet.provider,
                "any"
            );
            signer = ethersProvider.getSigner();
            const QuaniStakingAddress = stakingData.address;
            const QuaniStakingABI = stakingData.abi;
            const stakingInstance = new ethers.Contract(
                QuaniStakingAddress,
                QuaniStakingABI,
                signer
            );
            LoaderHelper.loaderStatus(true);
            await stakingInstance.calcuateRewards(wallet.accounts[0].address);
            const userStakedTokens = await stakingInstance.AddressToStakingDetails(
                wallet.accounts[0].address
            );
            const finalClaimableTokens = ethers.utils.formatUnits(
                userStakedTokens.claimableRewards
            );
            let resultString = finalClaimableTokens.toString().slice(0, 8);
            if (resultString > 0) {
                setUserClaimableTokens(resultString);
            } else setUserClaimableTokens("0.0");
            LoaderHelper.loaderStatus(false);
        } catch (err) {
            LoaderHelper.loaderStatus(false);
            console.log("error", err.message);
        }
    };


    useEffect(() => {
        if (!wallet) {
            connect();
        }
        else if (wallet) {
            ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any')
            signer = ethersProvider.getSigner();
            userAddress = wallet.accounts[0];
            let USERADDRESS = userAddress.address;
            const fetchData = async () => {
                try {
                    const QuaniAddress = tokenData.Quaniaddress
                    const QuaniABI = tokenData.abi;
                    const quaniInstance = new ethers.Contract(QuaniAddress, QuaniABI, signer);
                    const userTokenBalance = await quaniInstance.balanceOf(USERADDRESS);
                    const formattedBalance = ethers.utils.formatUnits(userTokenBalance);
                    setUSERTotalQuaniBalance(formattedBalance);
                    const USDT = usdtData.address;   //testnet Binance-Peg BSC-USD
                    const usdtABI = usdtData.abi;
                    const usdtInstance = new ethers.Contract(USDT, usdtABI, signer);
                    const userUSDTBalance = await usdtInstance.balanceOf(USERADDRESS);
                    const formattedUSDTBalance = ethers.utils.formatUnits(userUSDTBalance);
                    setUserUSDTBalance(formattedUSDTBalance);

                } catch (error) {
                    console.error('Error fetching data:', error.message);
                }
            }
            fetchData();
        }
    }, [USERTotalQuaniBalance, UserUSDTBalance, wallet]);

    useEffect(() => {
        fetchBuyPrice()
    }, []);

    return (
        <div class="wrap">
            <div class="content content-fluid">
                <div class="container-xl wide-xl" >
                    <div class="content-body" >
                        <HeaderNavbar />
                        <div class="sec_2 pt-0">
                            <div class="row">
                                <div class="col-lg-4">


                                    <div class="card-bodys c_bg_1 card-body_img ">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <img
                                                src="assets/img/ri_1.png"
                                                width="50"
                                                alt=""
                                                class="img-fluid"
                                            />
                                            <h6 className="text-dark">
                                                {" "}
                                                <b>
                                                    $
                                                    {USERTotalQuaniBalance == undefined
                                                        ? 0
                                                        : USERTotalQuaniBalance * PRICE_OF_QUANI}
                                                </b>{" "}
                                            </h6>
                                        </div>
                                        <span>Your Quani Balance</span>
                                        <h4>{USERTotalQuaniBalance}</h4>
                                    </div>

                                    <div class="card-bodys c_bg_2   card-body_img ">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <img
                                                src="assets/img/ri2.png"
                                                width="50"
                                                alt=""
                                                class="img-fluid"
                                            />
                                            <h6 className="text-dark">
                                                {" "}
                                                <b>
                                                    $
                                                    {userStakedToken == undefined
                                                        ? 0
                                                        : userStakedToken * PRICE_OF_QUANI}
                                                </b>{" "}
                                            </h6>
                                        </div>
                                        <span>Your Token Staked</span>
                                        <h4>{userStakedToken}</h4>
                                    </div>

                                    <div class="card-bodys c_bg_3  mb-0 card-body_img">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <img
                                                src="assets/img/ri3.png"
                                                width="50"
                                                alt=""
                                                class="img-fluid"
                                            />
                                            <h6 className="text-dark">
                                                {" "}
                                                <b>
                                                    $
                                                    {userClaimableTokens == undefined
                                                        ? 0
                                                        : userClaimableTokens * PRICE_OF_QUANI}
                                                </b>{" "}
                                            </h6>
                                        </div>
                                        <span className="d-flex align-items-center justify-content-between">
                                            {" "}
                                            Your Claimable Reward
                                            <button
                                                type="button"
                                                className="btn refresh_btn"
                                                onClick={() => updaterewards()}
                                            >
                                                {" "}
                                                Refresh{""}
                                            </button>
                                        </span>
                                        <h4 className="mb-0"> {userClaimableTokens}</h4>
                                        <span className="my-0">
                                            <small className="text-dark">
                                                Note refreshing cost GAS Fee
                                            </small>
                                        </span>
                                    </div>

                                </div>





                                <div class=" col-md-8 col-lg-8" >
                                    <div class="card  " >
                                        <div class="card-body" >
                                            <form>
                                                {!isReverse ?
                                                    <>
                                                        <div class="form-group " >
                                                            <label>Enter Quani Token Amount   </label>


                                                            <input type="number" class="form-control" placeholder="Enter Amount" onWheel={(e) => e.target.blur()} value={quaniToken === undefined ? 0 : quaniToken} onChange={(e) => { setQuaniToken(e.target.value); setPercentage(false); setUsdt(PRICE_OF_QUANI * e.target.value); setPercentageOption("") }} />
                                                        </div>

                                                        <div className="py-2 d-flex justify-contennt-center text-center">
                                                            <i class="ri-arrow-up-down-fill ar_hs  mx-auto cursor-pointer" style={{ fontSize: "20px" }} onClick={() => { setIsReverse((prev) => !prev); setUsdt(0); setQuaniToken(0); setPercentageOption("") }}></i>
                                                        </div>

                                                        <div class="form-group" >
                                                            <label>Enter USDT    </label>
                                                            <input type="number" class="form-control" placeholder="Enter Amount" value={usdt} />
                                                        </div>

                                                        <div className="row g-2 mb-4" >

                                                            <div className="col-3" >
                                                                <button className={`btn btn_gradient btn-sm  w-100 ${percentageOption === 25 && 'active'}`} style={{ padding: "0px 12px", marginRight: "4px" }} type="button" onClick={() => {
                                                                    try {
                                                                        if (!wallet) {
                                                                            connect();
                                                                        } else {
                                                                            setPercentageOption(25)
                                                                            setUsdt(UserUSDTBalance === undefined ? 0 : UserUSDTBalance * 0.25); setPercentage(true); setQuaniToken((UserUSDTBalance * 0.25) / PRICE_OF_QUANI)
                                                                        }
                                                                    }
                                                                    catch (error) { alert("Error") }
                                                                }}>25%</button>

                                                            </div>
                                                            <div className="col-3" >

                                                                <button className={`btn btn_gradient btn-sm  w-100 ${percentageOption === 50 && 'active'}`} style={{ padding: "0px 12px", marginRight: "4px" }} type="button" onClick={() => {
                                                                    try {
                                                                        if (!wallet) {
                                                                            connect();
                                                                        }
                                                                        else {
                                                                            setPercentageOption(50)
                                                                            setUsdt(UserUSDTBalance === undefined ? 0 : UserUSDTBalance * 0.50); setPercentage(true); setQuaniToken((UserUSDTBalance * 0.50) / PRICE_OF_QUANI)
                                                                        }
                                                                    } catch (error) {
                                                                        alert("Error")
                                                                    }
                                                                }}>50%</button>

                                                            </div>
                                                            <div className="col-3" >

                                                                <button className={`btn btn_gradient btn-sm  w-100 ${percentageOption === 75 && 'active'}`} style={{ padding: "0px 12px", marginRight: "4px" }} type="button" onClick={() => {
                                                                    try {
                                                                        if (!wallet) {
                                                                            connect();
                                                                        }
                                                                        else {
                                                                            setPercentageOption(75)
                                                                            setUsdt(UserUSDTBalance === undefined ? 0 : UserUSDTBalance * 0.75); setPercentage(true); setQuaniToken((UserUSDTBalance * 0.75) / PRICE_OF_QUANI)
                                                                        }
                                                                    } catch (error) {
                                                                        alert("Error")
                                                                    }
                                                                }}>75%</button>

                                                            </div>
                                                            <div className="col-3" >

                                                                <button className={`btn btn_gradient btn-sm  w-100 ${percentageOption === 100 && 'active'}`} style={{ padding: "0px 12px", marginRight: "4px" }} type="button" onClick={() => {
                                                                    try {
                                                                        if (!wallet) {
                                                                            connect();
                                                                        } else {
                                                                            setPercentageOption(100)
                                                                            setUsdt(UserUSDTBalance === undefined ? 0 : UserUSDTBalance); setPercentage(true); setQuaniToken((UserUSDTBalance) / PRICE_OF_QUANI)
                                                                        }
                                                                    } catch (error) {
                                                                        alert("Error")
                                                                    }
                                                                }}>1000%</button>
                                                            </div>
                                                        </div>

                                                    </> : <>

                                                        <div class="form-group  " >
                                                            <label>Enter USDT   </label>


                                                            <input type="number" class="form-control" placeholder="Enter Amount" value={usdt} onWheel={(e) => e.target.blur()} onChange={(e) => { setUsdt(e.target.value); setPercentage(false); setQuaniToken(e.target.value / PRICE_OF_QUANI); setPercentageOption("") }} />
                                                        </div>

                                                        <div className="py-2 d-flex justify-contennt-center text-center">
                                                            <i class="ri-arrow-up-down-fill ar_hs  mx-auto cursor-pointer" style={{ fontSize: "20px" }} onClick={() => { setIsReverse((prev) => !prev); setUsdt(0); setQuaniToken(0); setPercentageOption("") }}></i>
                                                        </div>


                                                        <div class="form-group  " >
                                                            <label>Enter Quani Token Amount   </label>
                                                            <input type="text" class="form-control" placeholder="Enter Amount" value={quaniToken} />
                                                        </div>


                                                        <div className="row g-2 mb-4" >
                                                            <div className="col-3" >
                                                                <button className={`btn btn_gradient btn-sm  w-100 ${percentageOption === 25 && 'active'}`} style={{ padding: "0px 12px", marginRight: "4px" }} type="button" onClick={() => {
                                                                    try {
                                                                        if (!wallet) {
                                                                            connect()
                                                                        } else {
                                                                            setPercentageOption(25)
                                                                            setUsdt(UserUSDTBalance === undefined ? 0 : UserUSDTBalance * 0.25); setPercentage(true); setQuaniToken((UserUSDTBalance * 0.25) / PRICE_OF_QUANI)
                                                                        }
                                                                    } catch (error) {
                                                                        alert("Error")
                                                                    }

                                                                }}>25%</button>
                                                            </div>

                                                            <div className="col-3" >
                                                                <button className={`btn btn_gradient btn-sm  w-100 ${percentageOption === 50 && 'active'}`} style={{ padding: "0px 12px", marginRight: "4px" }} type="button" onClick={() => {
                                                                    try {
                                                                        if (!wallet) {
                                                                            connect();
                                                                        } else {
                                                                            setPercentageOption(50)
                                                                            setUsdt(UserUSDTBalance === undefined ? 0 : UserUSDTBalance * 0.50); setPercentage(true); setQuaniToken((UserUSDTBalance * 0.50) / PRICE_OF_QUANI)
                                                                        }
                                                                    } catch (error) {
                                                                        alert("Error")
                                                                    }
                                                                }}>50%</button>
                                                            </div>

                                                            <div className="col-3" >
                                                                <button className={`btn btn_gradient btn-sm  w-100 ${percentageOption === 75 && 'active'}`} style={{ padding: "0px 12px", marginRight: "4px" }} type="button" onClick={() => {
                                                                    try {
                                                                        if (!wallet) {
                                                                            connect();
                                                                        } else {
                                                                            setPercentageOption(75)
                                                                            setUsdt(UserUSDTBalance === undefined ? 0 : UserUSDTBalance * 0.75); setPercentage(true); setQuaniToken((UserUSDTBalance * 0.75) / PRICE_OF_QUANI)
                                                                        }
                                                                    } catch (error) {
                                                                        alert("Error")
                                                                    }
                                                                }}>75%</button>
                                                            </div>

                                                            <div className="col-3" >
                                                                <button className={`btn btn_gradient btn-sm  w-100 ${percentageOption === 100 && 'active'}`} style={{ padding: "0px 12px", marginRight: "4px" }} type="button" onClick={() => {
                                                                    try {
                                                                        if (!wallet) {
                                                                            connect()
                                                                        } else {
                                                                            setPercentageOption(100)
                                                                            setUsdt(UserUSDTBalance === undefined ? 0 : UserUSDTBalance); setPercentage(true); setQuaniToken((UserUSDTBalance) / PRICE_OF_QUANI)
                                                                        }
                                                                    } catch (error) {
                                                                        alert("Error")
                                                                    }
                                                                }}>100%</button>
                                                            </div>
                                                        </div>
                                                    </>}

                                                <div class="balance-libra card-success mb-4">
                                                    <div class="token-img-bg_right" id="lc-data"> 1 Quani Token  = {PRICE_OF_QUANI} BSC-USD</div>
                                                    <div class="token-img-bg_right" id="lc-data"> Network : BNB Smart Chain Mainnet</div>
                                                </div>

                                                <div class="form-group mb-0" >
                                                    <button class="btn btn-primary btn_gradient btn-block w-100" type="button" onClick={() => BuyOrSwap()} disabled={!quaniToken || !usdt}
                                                    > Buy Quani Token   </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
export default SwapingBuySystemforMe