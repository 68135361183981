import React, { useState } from "react";

const Header = () => {
    const [isSidebarActive, setSidebarActive] = useState(false);

    const openSidebar = () => {
        setSidebarActive(!isSidebarActive);
        let para = document.getElementById("sidebar");

        if (para.classList.contains("sidebar-active")) {
            para.classList.remove("sidebar-active");
        }
        else {
            para.classList.add("sidebar-active");
        }
    };

    return (
        <div className="header_top">
            <div className="h_row">
                <div className="h_left">
                    <span onClick={openSidebar} className="nav-toggle quick-nav-icon text-white">
                        <i className="ri-menu-4-fill"></i>
                    </span>

                    <a href="/" className="ms-2">
                        <img src="assets/img/logo.png" alt="Logo" className="img-fluid" />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Header;
