import QalaxyFooter from "../CustomComponents/QalaxyFooter"
import QalaxyHeader from "../CustomComponents/QalaxyHeader"

const SmartStaking = () =>{
    return(
        <>
          <div class="site-content">

          <section class="earnt-table-wrapper">
              <div class="container">
                  <div class="tab-wrapper-earn">
                      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                          <li class="nav-item">
                              <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Locked Staking</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">DeFi Staking</a>
                          </li>
                      </ul>
                      <div class="tab-content" id="pills-tabContent">
                          <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                              <div class="table-responsive">
                              <table class="table">
                                  <thead class="thead-light">
                                      <tr>
                                          <th >Token</th>
                                          <th >Est.APY</th>
                                          <th >Duration(days)</th>
                                          <th colspan="2">Minimum Locked Amount</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr>
                                          <td>
                                              <span class="user-class"> <img src="qalaxyAssets/img/round-new.png" />MBOX</span>
                                          </td>
                                          <td><span class="est">18.79</span></td>

                                          <td>
                                              <ul class="listednumbers">
                                                  <li class="active">10</li>
                                                  <li>20</li>
                                                  <li>30</li>
                                              </ul>
                                          </td>
                                          <td>0.1MBOX</td>
                                          <td><a href="/staking" class="stake-now-btn">stake now</a></td>
                                      </tr>

                                           <tr>
                                          <td>
                                              <span class="user-class"> <img src="qalaxyAssets/img/round-new.png" />MBOX</span>
                                          </td>
                                          <td><span class="est">18.79</span></td>

                                          <td>
                                              <ul class="listednumbers">
                                                  <li class="active">10</li>
                                                  <li>20</li>
                                                  <li>30</li>
                                              </ul>
                                          </td>
                                          <td>0.1MBOX</td>
                                          <td><a href="/staking" class="stake-now-btn">stake now</a></td>
                                      </tr>

                                           <tr>
                                          <td>
                                              <span class="user-class"> <img src="qalaxyAssets/img/round-new.png" />MBOX</span>
                                          </td>
                                          <td><span class="est">18.79</span></td>

                                          <td>
                                              <ul class="listednumbers">
                                                  <li class="active">10</li>
                                                  <li>20</li>
                                                  <li>30</li>
                                              </ul>
                                          </td>
                                          <td>0.1MBOX</td>
                                          <td><a href="/staking" class="stake-now-btn">stake now</a></td>
                                      </tr>

                                           <tr>
                                          <td>
                                              <span class="user-class"> <img src="qalaxyAssets/img/round-new.png" />MBOX</span>
                                          </td>
                                          <td><span class="est">18.79</span></td>

                                          <td>
                                              <ul class="listednumbers">
                                                  <li class="active">10</li>
                                                  <li>20</li>
                                                  <li>30</li>
                                              </ul>
                                          </td>
                                          <td>0.1MBOX</td>
                                          <td><a href="/staking" class="stake-now-btn">stake now</a></td>
                                      </tr>
                                    
                                  </tbody>
                              </table>
                          </div>
                          </div>
                          <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                              
                                    <div class="table-responsive">
                              <table class="table">
                                  <thead class="thead-light">
                                      <tr>
                                          <th >Token</th>
                                          <th >Est.APY</th>
                                          <th >Duration(days)</th>
                                          <th colspan="2">Minimum Locked Amount</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr>
                                          <td>
                                              <span class="user-class"> <img src="qalaxyAssets/img/round-new.png" />MBOX</span>
                                          </td>
                                          <td><span class="est">18.79</span></td>

                                          <td>
                                              <ul class="listednumbers">
                                                  <li class="active">10</li>
                                                  <li>20</li>
                                                  <li>30</li>
                                              </ul>
                                          </td>
                                          <td>0.1MBOX</td>
                                          <td><a href="/staking" class="stake-now-btn">stake now</a></td>
                                      </tr>

                                           <tr>
                                          <td>
                                              <span class="user-class"> <img src="qalaxyAssets/img/round-new.png" />MBOX</span>
                                          </td>
                                          <td><span class="est">18.79</span></td>

                                          <td>
                                              <ul class="listednumbers">
                                                  <li class="active">10</li>
                                                  <li>20</li>
                                                  <li>30</li>
                                              </ul>
                                          </td>
                                          <td>0.1MBOX</td>
                                          <td><a href="/staking" class="stake-now-btn">stake now</a></td>
                                      </tr>

                                           <tr>
                                          <td>
                                              <span class="user-class"> <img src="qalaxyAssets/img/round-new.png" />MBOX</span>
                                          </td>
                                          <td><span class="est">18.79</span></td>

                                          <td>
                                              <ul class="listednumbers">
                                                  <li class="active">10</li>
                                                  <li>20</li>
                                                  <li>30</li>
                                              </ul>
                                          </td>
                                          <td>0.1MBOX</td>
                                          <td><a href="/staking" class="stake-now-btn">stake now</a></td>
                                      </tr>

                                           <tr>
                                          <td>
                                              <span class="user-class"> <img src="qalaxyAssets/img/round-new.png" />MBOX</span>
                                          </td>
                                          <td><span class="est">18.79</span></td>

                                          <td>
                                              <ul class="listednumbers">
                                                  <li class="active">10</li>
                                                  <li>20</li>
                                                  <li>30</li>
                                              </ul>
                                          </td>
                                          <td>0.1MBOX</td>
                                          <td><a href="/staking" class="stake-now-btn">stake now</a></td>
                                      </tr>
                                    
                                  </tbody>
                              </table>
                          </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
       
        <QalaxyFooter/>
      </div>



      <div class="meta-mask modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
              <div class="modal-content">
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                  </button>

                  <div class="modal-body">
                      <div class="comman-content-meta">
                          <h3>Hey there</h3>
                          <span>you can</span>
                          <h3>connect to wallet</h3>
                          <span>now</span>
                      </div>

                      <div class="select-meta-box">
                          <div class="select-box-comman">
                              <select class="form-select" aria-label="Default select example">
                                  <option selected>metamask</option>
                                  <option value="1">Wallet options</option>
                              </select>
                          </div>

                          <button>confirm</button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
        </>
    )
}

export default SmartStaking