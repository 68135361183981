import QalaxyFooter from "../CustomComponents/QalaxyFooter"
import QalaxyHeader from "../CustomComponents/QalaxyHeader"

const Community = () =>{
    return(
        <>
         <div class="site-content">
   
   {/* <!-- header-start--> */}
      {/* <QalaxyHeader/> */}
          {/* <!-- header-closed-->
          <!--- feature-slider-wrapper-start--> */}
          <section class="fature-slider-wrapper comunity">
                <div class="start">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div class="container">
                    <figure><img src="qalaxyAssets/img/round.png" alt="" /></figure>
                    <div class="slider-content-inner">
                        <h1>
                        Join the Qalaxy Labs Community
                        </h1>
                        <p>Connect with fellow visionaries, enthusiasts, and the Qalaxy Labs team across our vibrant social media channels. Stay updated with the latest news, engage with our content, and be the first to know about new developments and opportunities.</p>
                 
                    </div>
                </div>
            </section>

            <section class="social-menu-list-wrapper">
                <div class="container">
                    <div class="filed-inner-social">
                        <div class="row">
                            <div class="col-md-3">
                                <article>
                                    <a href="https://lnkd.in/ejhruvNP">
                                        <figure><img src="qalaxyAssets/img/z1.png" alt="" /></figure>
                                        <h4>Twitter</h4>
                                    </a>
                                </article>
                            </div>

                            <div class="col-md-3">
                                <article>
                                    <a href="https://t.me/QalaxyLabsProject">
                                        <figure><img src="qalaxyAssets/img/z2.png" alt="" /></figure>
                                        <h4>Telegram</h4>
                                    </a>
                                </article>
                            </div>

                            <div class="col-md-3">
                                <article>
                                    <a href="https://lnkd.in/eXRvcRS6">
                                        <figure><img src="qalaxyAssets/img/z3.png" alt="" /></figure>
                                        <h4>Discord</h4>
                                    </a>
                                </article>
                            </div>

                            <div class="col-md-3">
                                <article>
                                    <a href="https://www.instagram.com/qalaxylabs/">
                                        <figure><img src="qalaxyAssets/img/z4.png" alt="" /></figure>
                                        <h4>Instagram</h4>
                                    </a>
                                </article>
                            </div>

                            <div class="col-md-3">
                                <article>
                                    <a href="https://www.facebook.com/Qalaxy-Labs-107489775111278">
                                        <figure><img src="qalaxyAssets/img/z5.png" alt="" /></figure>
                                        <h4>Facebook</h4>
                                    </a>
                                </article>
                            </div>

                            <div class="col-md-3">
                                <article>
                                    <a href="https://lnkd.in/ezyf88Ez">
                                        <figure><img src="qalaxyAssets/img/z6.png" alt="" /></figure>
                                        <h4>Reddit</h4>
                                    </a>
                                </article>
                            </div>

                            <div class="col-md-3">
                                <article>
                                    <a href="https://lnkd.in/ew924saR">
                                        <figure><img src="qalaxyAssets/img/z7.png" alt="" /></figure>
                                        <h4>Medium</h4>
                                    </a>
                                </article>
                            </div>

                            <div class="col-md-3">
                                <article>
                                    <a href="https://lnkd.in/ef_7KU43">
                                        <figure><img src="qalaxyAssets/img/z8.png" alt="" /></figure>
                                        <h4>YouTube</h4>
                                    </a>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div class="text-center  mb-5">
                <div class="container">
                   <span class="touch-comman">Let’s keep in touch </span>
               </div>
               </div>
          {/* <!-- footer-start--> */}
          <QalaxyFooter/>
          {/* <!-- footer-closed--> */}
      </div>
      {/* <!-- meta-mask-modal-start--> */}
      <div class="meta-mask modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
              <div class="modal-content">
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                  </button>
                  <div class="modal-body">
                      <div class="comman-content-meta">
                          <h3>Hey there</h3>
                          <span>you can</span>
                          <h3>connect to wallet</h3>
                          <span>now</span>
                      </div>
                      <div class="select-meta-box">
                          <div class="select-box-comman">
                              <select class="form-select" aria-label="Default select example">
                                  <option selected>metamask</option>
                                  <option value="1">Wallet options</option>
                              </select>
                          </div>
                          <button>confirm</button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
        </>
    )
}

export default Community