import Routing from './Routing';
import Header from './CustomComponents/Header';
import Loading from './CustomComponents/Loading';
import LoaderHelper from './CustomComponents/Loading/LoaderHelper';
import { init, useConnectWallet,Web3OnboardProvider } from '@web3-onboard/react'
import injectedModule, { ProviderLabel }  from '@web3-onboard/injected-wallets'
import walletConnectModule from '@web3-onboard/walletconnect'


const injected = injectedModule({
  displayUnavailable: [ProviderLabel.MetaMask, ProviderLabel.Trust],
  filter: {
    [ProviderLabel.Detected]: ['Android', 'desktop','macOS','iOS','Android Browser']
  }
})
const wcV2InitOptions = {
  projectId: '87d00e656d33328d6aa38cfd97c18969',
  requiredChains: [
    56
  ],
}

const walletConnect = walletConnectModule(wcV2InitOptions)

const web3Onboard = init({
    wallets: [
      injected,
      walletConnect
    ],
    theme: 'dark',
    connect: {
      autoConnectLastWallet: false
    },
    chains: [
      {
        id: '0x38',
        token: 'BNB',
        label: 'Binance Smart Chain',
        rpcUrl: 'https://rpc.ankr.com/bsc/0dd03e2d1e8eefaf8c881b63d7ed3244be38abd1406517a45cafd0f0979f8b25'
      },
    ],
  
  })
  

function App() {

  return (
    <div className="App">
       <Web3OnboardProvider web3Onboard={web3Onboard}>
        <Routing />
        <Loading ref={ref => LoaderHelper.setLoader(ref)} />
        </Web3OnboardProvider>
    </div>
  );
}

export default App;
