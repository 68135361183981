import QalaxyFooter from "../CustomComponents/QalaxyFooter"
import QalaxyHeader from "../CustomComponents/QalaxyHeader"

const QalToken = () =>{
    return(
        <>
        <div class="site-content">
  {/* <!-- header-start--> */}
         {/* <QalaxyHeader/> */}
            {/* {/* <!-- header-closed--> */}

            <section class="fature-slider-wrapper qal-token full-height-box-vh">
            <div class="start"> <span></span> <span></span> <span></span> </div>
            <div class="container"> <span class="qalone"><img src="qalaxyAssets/img/gold1.png" alt="" /> </span> <span class="qaltwo"><img src="qalaxyAssets/img/gold2.png" alt="" /> </span> <span class="qalthree"><img src="qalaxyAssets/img/gold3.png" alt="" /> </span> <span class="qaltfour"><img src="qalaxyAssets/img/gold4.png" alt="" /> </span>
                <figure><img src="qalaxyAssets/img/round.png" alt="" /></figure>
                <div class="slider-content-inner">
                    <h1>Utility in QALVERSE </h1>
                    <p> QAL Tokens are poised to be the central medium of exchange within QALVERSE, facilitating transactions, access to services, and incentivizing various activities. Their utility spans purchasing virtual goods, accessing exclusive areas, and participating in governance and other ecosystem-enhancing actions. With this strategic tokenomic structure, Qalaxy Labs ensures that QAL Tokens are not just a currency but an integral component of a thriving digital ecosystem, promoting growth, stability, and an immersive user experience. </p>
                </div>
            </div>
        </section>


        <section class="comman-vectors-wrapper full-height-box-vh">
            <div class="container">
                <div class="row align-items-center flex-sm-column-reverses">
                    <div class="col-md-6">
                        <div class="comman-vector-content">
                            <h3>Available on </h3>
                            <div class="inners-imgss"> <img src="qalaxyAssets/img/logot1.png" alt="" /> <img src="qalaxyAssets/img/logot2.png" alt="" /> <img src="qalaxyAssets/img/logot3.png" alt="" /> </div>
                            <p>QAL Token is the cornerstone of Qalaxy Labs' digital ecosystem, designed to provide users with a secure and versatile means of transaction within the QALVERSE. It represents a significant leap in the crypto domain, offering utility across various applications, thanks to its creation on the Polygon blockchain, ensuring enhanced privacy, security, and transparency.</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="vector-shapes text-center"> <img src="qalaxyAssets/img/money-tree.png" alt="" /> </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="comman-vectors-wrapper full-height-box-vh">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="vector-shapes text-center"> <img src="qalaxyAssets/img/crosswalk.png" alt="" /> </div>
                    </div>
                    <div class="col-md-6">
                        <div class="comman-vector-content">
                            <h3 class="d-flex justify-content-center"> created on    <img  class="pl-3" src="qalaxyAssets/img/logot7.png" alt="" /></h3>
                            <h2>
                                    cross-chain On<br />
                                 
                                </h2>
                            <div class="inners-imgss"> <img src="qalaxyAssets/img/logot4.png" alt="" /> <img src="qalaxyAssets/img/logot5.png" alt="" /> <img src="qalaxyAssets/img/logot6.png" alt="" /> </div>
                            <p>The QAL Token is the cornerstone of Qalaxy Labs' digital ecosystem, designed to provide users with a secure and versatile means of transaction within the QALVERSE. It represents a significant leap in the crypto domain, offering utility across various applications, thanks to its creation on the Polygon blockchain, ensuring enhanced privacy, security, and transparency.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="passive-box-comman-wrapper">
            <div class="container">
                <h4>Tokenomics</h4>
                <div class="table-ql-token">
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Stages</th>
                                    <th>Share</th>
                                    <th>Tokens #</th>
                                    <th>TGE</th>
                                    <th>Cliff</th>
                                    <th>Linear Vesting</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Seed Sale</td>
                                    <td>6%</td>
                                    <td>30,000,000</td>
                                    <td>5%</td>
                                    <td>NO</td>
                                    <td>12 Months</td>
                                    <td>5% TGE, linear vesting for next 12 months</td>
                                </tr>
                                <tr>
                                    <td>Private Sale</td>
                                    <td>14%</td>
                                    <td>70,000,000</td>
                                    <td>10%</td>
                                    <td>NO</td>
                                    <td>12 Months</td>
                                    <td>10% TGE, linear vesting for next 12 months</td>
                                </tr>
                                <tr>
                                    <td>Public Sale</td>
                                    <td>2%</td>
                                    <td>10,000,000</td>
                                    <td>50%</td>
                                    <td>NO</td>
                                    <td>5 Months</td>
                                    <td>50% TGE, linear vesting for next 5 months</td>
                                </tr>
                                <tr>
                                    <td>Initial Liquidity</td>
                                    <td>3%</td>
                                    <td>15,000,000</td>
                                    <td>100%</td>
                                    <td>NO</td>
                                    <td></td>
                                    <td>100% at TGE</td>
                                </tr>
                                <tr>
                                    <td>Marketing & Developments</td>
                                    <td>16%</td>
                                    <td>80,000,000</td>
                                    <td>0%</td>
                                    <td>NO</td>
                                    <td>16 Months</td>
                                    <td>Linear vesting over 16 months from TGE</td>
                                </tr>
                                <tr>
                                    <td>Community Rewards</td>
                                    <td>42%</td>
                                    <td>210,000,000</td>
                                    <td>0%</td>
                                    <td>NO</td>
                                    <td>25 Months</td>
                                    <td>Linear vesting over 25 months from TGE</td>
                                </tr>
                                <tr>
                                    <td>Team</td>
                                    <td>15%</td>
                                    <td>75,000,000</td>
                                    <td>0%</td>
                                    <td>NO</td>
                                    <td>9 Months</td>
                                    <td>9 months Cliff, Linear vesting in next 16 months</td>
                                </tr>
                                <tr>
                                    <td>Advisors</td>
                                    <td>2%</td>
                                    <td>10,000,000</td>
                                    <td>0%</td>
                                    <td>3 Months</td>
                                    <td>16 Months</td>
                                    <td>3 months Cliff, Linear vesting in next 16 months</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="2">TOTAL</td>
                                    <td>500,000,000</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </section>
        <section class="qalaxy-content-wrapper mb-5">
            <div class="container">
                <h2>The QAL Token from Qalaxy Labs is meticulously crafted to serve as a utility token within the vibrant QALVERSE. With a total supply of 500 million tokens, QAL Token's distribution has been thoughtfully designed to support and sustain the ecosystem's growth and to ensure a fair and beneficial rollout for all stakeholders involved.</h2>
                <h4>Tokenomics of QAL Token</h4>
                <ul>
                    <li><span>1.</span> Seed Sale: 6% of the total supply, amounting to 30,000,000 QAL Tokens, was initially offered during the seed sale phase. Participants in this phase are subject to a 12-month linear vesting period post an initial 5% Token Generation Event (TGE) to foster long-term commitment.</li>
                    <li><span>2.</span> Private Sale: With 14% of the total tokens, or 70,000,000 QAL, this sale provided early supporters with a stake in the platform’s future. These tokens also vest over 12 months following a 10% distribution at TGE, aligning private investors with the platform's growth trajectory.</li>
                    <li><span>3.</span> Public Sale: Accounting for 2% of the total, this phase put 10,000,000 QAL Tokens into circulation, with half available at TGE and the remainder vested over the following 5 months to integrate public stakeholders smoothly into the economy.</li>
                    <li><span>4. </span> Initial Liquidity: 3% or 15,000,000 tokens have been allocated to ensure the fluidity of QAL Tokens within the market, available 100% at TGE to facilitate immediate trading capabilities.</li>
                    <li><span>5.</span> Marketing & Developments: To propel the QAL Token's presence and fund ongoing platform enhancements, 16%, which is 80,000,000 tokens, are reserved. These are vested over 16 months post-TGE, securing the means for sustained marketing and development efforts.</li>
                    <li><span>6. </span> Community Rewards: Reflecting Qalaxy Labs' community-centric approach, a significant 42% (210,000,000 QAL Tokens) is earmarked for community rewards, vested over 25 months from TGE. This allocation aims to incentivize community engagement and reward participation within the QALVERSE.</li>
                    <li><span>7.</span> Team Allocation: 15% or 75,000,000 tokens dedicated to the team come with a 9-month cliff, post which vesting occurs linearly over the next 16 months. This ensures the team remains motivated and focused on the project's long-term success.</li>
                    <li><span>8. </span> Advisors: Advisors, with their strategic guidance, are allotted 1% or 10,000,000 tokens, showing gratitude for their expertise with a 3-month cliff and subsequent 16-month linear vesting.</li>
                </ul>
                <p>The remaining tokens are set aside for liquidity and reserve purposes, as well as for partnerships and acquisitions, which are crucial for the platform's expansion and collaborative efforts.</p>
            </div>
        </section>

            {/* <!-- footer-start--> */}
           <QalaxyFooter/>
            {/* <!-- footer-closed--> */}
        </div>
        {/* <!-- meta-mask-modal-start--> */}
        <div class="meta-mask modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="modal-body">
                        <div class="comman-content-meta">
                            <h3>Hey there</h3>
                            <span>you can</span>
                            <h3>connect to wallet</h3>
                            <span>now</span>
                        </div>
                        <div class="select-meta-box">
                            <div class="select-box-comman">
                                <select class="form-select" aria-label="Default select example">
                                    <option selected>metamask</option>
                                    <option value="1">Wallet options</option>
                                </select>
                            </div>
                            <button>confirm</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default QalToken