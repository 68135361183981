import QalaxyFooter from "../CustomComponents/QalaxyFooter"
import QalaxyHeader from "../CustomComponents/QalaxyHeader"

const RoboTrdr = () =>{
    return(
        <>
        <div class="site-content">


        <section class="fature-slider-wrapper comunity wpbx">
                <div class="start">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div class="container">         

                    <figure><img src="qalaxyAssets/img/round.png" alt="" /></figure>
                    <div class="banner-logo-content">
                        <img src="qalaxyAssets/img/logo-robo.png" alt="img" />
                        <p>Discover roboTRDR: the pinnacle of AI trading. Our intelligent platform automates your asset management, providing a flawless trading experience designed to maximize profits and eliminate human error.</p>
                    </div>
                </div>
            </section>
            <section class="smart-section">
                <div class="container">
                    <div class="inner-blog d-flex">
                        <div class="left-se">
                            <figure><img src="qalaxyAssets/img/smart-img.png" alt="img"/></figure>
                        </div>
                        <div class="right-c-se">
                            <div class="content-se">
                                <h2>the futuristic and smart crypto trading robot ensures</h2>
                                <p>Speedy and accurate transactions Zero errors, as zero human emotions involved Zero stress, as your money grows automatically</p>
                              

                                <div class="over-month">
                                    <div class="over-box">
                                        <figure><img src="qalaxyAssets/img/over-img.png" alt="img" /></figure>
                                        <div class="over-month-content">
                                            <span>UPTO</span>
                                            <strong>60%</strong>
                                            <p>  ANNUALNY</p>
                                        </div>
                                    </div>
                                    <div class="month-box">
                                        <figure><img src="qalaxyAssets/img/mont-img.png" alt="img" /></figure>
                                        <div class="over-month-content">
                                            <span>average monthly  </span>
                                            <strong>5%</strong>
                                            <span>on 60M STAKING  </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="choose-section">
                <div class="container">
                    <div class="choose-inner">
                        <div class="heading-title">
                            <h2>Choose Robo TRDR and enter a world of possibilities with massive profitability</h2>
                        </div> 
                        <div class="choose-content">
                        
                        <div class="explore-more stad-btn text-center">
                                    <a href="#" class="text-center ">Explore More</a>
                                </div>
                        </div>
                    </div>
                </div>
            </section>

            
           
          <QalaxyFooter/>
      </div>
      <div class="meta-mask modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
              <div class="modal-content">
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                  </button>
                  <div class="modal-body">
                      <div class="comman-content-meta">
                          <h3>Hey there</h3>
                          <span>you can</span>
                          <h3>connect to wallet</h3>
                          <span>now</span>
                      </div>
                      <div class="select-meta-box">
                          <div class="select-box-comman">
                              <select class="form-select" aria-label="Default select example">
                                  <option selected>metamask</option>
                                  <option value="1">Wallet options</option>
                              </select>
                          </div>
                          <button>confirm</button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
        </>
    )
}

export default RoboTrdr