import QalaxyFooter from "../CustomComponents/QalaxyFooter"
import QalaxyHeader from "../CustomComponents/QalaxyHeader"

const Tayc = () =>{
    return(
        <>
        <div class="site-content">


            <section class="coming-soon-new-wrapper tayc">
                <div class="container">
                    <span class="shape-doup"><img src="qalaxyAssets/img/tayct.png" alt="" /></span>
                    <span class="shape-flight-pd"><img src="qalaxyAssets/img/l-7.png" alt="" /></span>

                    <div class="main-coming-shape wow slideInRight z-index-1" data-wow-duration="3s" data-wow-delay="0.5s">
                        <img src="qalaxyAssets/img/ap2.png" alt="" />
                    </div>

                    <div class="coming-soon-main-img">
                        <img src="qalaxyAssets/img/whiliste.png" alt="" />
                    </div>
                </div>
            </section>

            <section class="content-wrapper-tayc">
                <div class="container">
               
                    <p>
                        Welcome to TAYC, the heart of digital artistry within the QALVERSE. Here, innovation meets legacy through the power of Non-Fungible Tokens (NFTs).
                    </p>

                    


                    
                </div>
            </section> 

            {/* <!--passive-income-wrapper-start--> */}
            <section class="passive-income-wrapper full-height-box tyce-c">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="passivemain-inner">
                         
                                <div class="passive-img">
                                    <figure class="wow slideInUp" data-wow-duration="3s" data-wow-delay="0.3s"><img src="qalaxyAssets/img/rocket-3.png" alt="" /></figure>
                                 
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="passive-content top">
                              
                    <h3>Utility and Features</h3>

                    <p>At TAYC, NFTs go beyond art; they are gateways to experiences, utilities, and rewards within the QALVERSE, offering tangible value in the virtual world.</p>

                    <h3>Creator and Collector Support</h3>

                    <p>We empower creators with the tools to mint and market their NFTs while providing collectors with a secure, transparent platform for transactions.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            
           
          <QalaxyFooter/>
      </div>
      <div class="meta-mask modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
              <div class="modal-content">
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                  </button>
                  <div class="modal-body">
                      <div class="comman-content-meta">
                          <h3>Hey there</h3>
                          <span>you can</span>
                          <h3>connect to wallet</h3>
                          <span>now</span>
                      </div>
                      <div class="select-meta-box">
                          <div class="select-box-comman">
                              <select class="form-select" aria-label="Default select example">
                                  <option selected>metamask</option>
                                  <option value="1">Wallet options</option>
                              </select>
                          </div>
                          <button>confirm</button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
        </>
    )
}

export default Tayc