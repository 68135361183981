import QalaxyFooter from "../CustomComponents/QalaxyFooter"
import QalaxyHeader from "../CustomComponents/QalaxyHeader"

const HowStakingWorksQealth = () => {
    return (
        <>
          <div class="site-content pt-0">

                <section class="fature-slider-wrapper comunity wpbx full-grid-height">
                    <div class="start">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div class="container">

                        <figure><img src="qalaxyAssets/img/round.png" alt="" /></figure>
                        <div class="slider-content-inner">
                            <h1>staking quani </h1>
                            <p>
                                Begin Your Staking Journey Discover the simplicity of earning rewards with QUANI staking. Begin by acquiring QUANI tokens, which unlock the door to an innovative staking experience within the QEALTH ecosystem.
                            </p>
                        </div>
                    </div>
                </section>
                <section class="smart-section">
                    <div class="container">
                        <div class="inner-blog d-flex">
                            <div class="left-se">
                                <figure><img src="qalaxyAssets/img/smart-img.png" alt="img" /></figure>
                            </div>
                            <div class="right-c-se">
                                <div class="content-se">
                                    <h2>Stake and Earn </h2>
                                    <p>Once you hold QUANI tokens, choose a staking program that aligns with your financial goals. Our intuitive platform allows you to stake your tokens easily, setting you on a path to earn competitive returns.</p>

                                    <h2>Robust Rewards System  </h2>

                                    <p> Stake your QUANI tokens and join a rewarding system that offers up to 60% APY. Our rewards are designed to be proportionate to
                                        your contribution, ensuring a fair and lucrative staking experience.</p>

                                    <h2>Secure and Transparent </h2>

                                    <p>Your staked QUANI tokens are secured by cutting-edge blockchain technology, providing transparency and peace of mind. Watch your portfolio grow in a fully auditable ecosystem.</p>




                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="choose-section mb-5" >
                    <div class="container">
                        <div class="choose-inner">
                            <div class="heading-title">
                                <h2>Unstake at Your Convenience Enjoy the flexibility to unstake QUANI when needed. </h2>
                            </div>
                            <div class="choose-content">


                                <div class="explore-more stad-btn text-center">
                                    <a href="#" class="text-center ">Explore More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <QalaxyFooter />
            </div>



            <div class="meta-mask modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>

                        <div class="modal-body">
                            <div class="comman-content-meta">
                                <h3>Hey there</h3>
                                <span>you can</span>
                                <h3>connect to wallet</h3>
                                <span>now</span>
                            </div>

                            <div class="select-meta-box">
                                <div class="select-box-comman">
                                    <select class="form-select" aria-label="Default select example">
                                        <option selected>metamask</option>
                                        <option value="1">Wallet options</option>
                                    </select>
                                </div>

                                <button>confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HowStakingWorksQealth