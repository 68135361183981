import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function alertErrorMessage(message) {
  toast.error(message ? message?.toUpperCase() : 'Network Error...Please try again later', {
    toastId: 'Toasterror',
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    closeButton: false,
  });
}

function alertSuccessMessage(message) {
  toast.success(message ? message?.toUpperCase() : 'Success', {
    toastId: 'Toastsuccess',
    position: 'top-center',
    hideProgressBar: true,
    autoClose: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
    closeButton: true
  });
}

function alertWarningMessage(message) {
  toast.info(message ? message?.toUpperCase() : 'Oops...Something went wrong', {
    toastId: 'ToastWarning',
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  })
};




export { alertErrorMessage, alertSuccessMessage, alertWarningMessage };