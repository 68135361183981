import React, { useState, useEffect } from "react";
import Web3 from "web3";
import HeaderNavbar from "./HeaderNavbar";
import usdtData from './UsdtContract.json'
import tokenData from './Contract.json'
import stakingData from './StakingContract.json'
import { useConnectWallet } from "@web3-onboard/react";
import LoaderHelper from "../CustomComponents/Loading/LoaderHelper"
import { ethers } from 'ethers'
import adminData from "./adminDepoite.json"
import { alertErrorMessage, alertSuccessMessage } from "../CustomComponents/CustomAlertMessage";
import { ApiCallGet } from "../ApiModule/APICall";

export const Dashboard = () => {
  const [totalSupply, settotalSupply] = useState("");
  const [currentRelesae, setCurrentrelease] = useState("");
  const [totalStakedTokens, setTotalStakedTokens] = useState("");
  const [lockedTokens, setlocked] = useState("")
  const [APY, setApy] = useState("");
  const [quaniToken, setQuaniToken] = useState()
  const [usdt, setUsdt] = useState()
  const [isReverse, setIsReverse] = useState(false)
  const [percentage, setPercentage] = useState(false)
  const [USERTotalQuaniBalance, setUSERTotalQuaniBalance] = useState()
  const [UserUSDTBalance, setUserUSDTBalance] = useState()
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet()
  const [percentageOption, setPercentageOption] = useState('');
  const [PRICE_OF_QUANI, setPRICE_OF_QUANI] = useState(0);

  const [userBalance, setUserBalance] = useState();
  const [userStaked, setUserSraked] = useState();
  const [userClaimable, setUserClaimable] = useState();


  // const PRICE_OF_QUANI = 0.3;
  const web3 = new Web3("https://rpc.ankr.com/bsc/0dd03e2d1e8eefaf8c881b63d7ed3244be38abd1406517a45cafd0f0979f8b25");
  const QuaniAddress = tokenData.Quaniaddress;
  const QuaniABI = tokenData.abi;
  const quaniInstance = new web3.eth.Contract(QuaniABI, QuaniAddress);
  const QuaniStakingAddress = stakingData.address
  const QuaniStakingABI = stakingData.abi;
  const stakingInstance = new web3.eth.Contract(QuaniStakingABI, QuaniStakingAddress);
  const adminWallet = adminData.address;
  const USER_BALANCE_QUANI = USERTotalQuaniBalance;
  let ethersProvider;
  let signer;
  let userAddress;


  const fetchBuyPrice = async () => {
    LoaderHelper.loaderStatus(true)
    let url = "https://preprod.unicas.co.in/get-quani-price";
    const headers = {
      'Content-Type': 'application/json',
    };
    try {
      const result = await ApiCallGet(url, headers)
      setPRICE_OF_QUANI(result?.statics?.sell_rate?.toFixed(5))
      LoaderHelper.loaderStatus(false)
    } catch (error) {
      alertErrorMessage(error.message)
      LoaderHelper.loaderStatus(false)
    }
  };

  const BuyOrSwap = async () => {
    try {
      if (!wallet) {
        connect();
      }
      ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any')
      signer = ethersProvider.getSigner();
      const amountToStake = usdt;
      const amountToStakeInWei = ethers.utils.parseEther(amountToStake.toString());
      const USDT = usdtData.address;
      const usdtABI = usdtData.abi;
      const usdtInstance = new ethers.Contract(USDT, usdtABI, signer);
      LoaderHelper.loaderStatus(true);
      try {
        await usdtInstance.transfer(adminWallet, amountToStakeInWei);
        LoaderHelper.loaderStatus(false);
        alertSuccessMessage(`Thank you for your successfull transfer of USDT ${amountToStake} against purchase of QUANI ${quaniToken} you will receiving these QUANI on your BSC wallet address ${wallet.accounts[0].address}`)
      } catch (error) {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage('Transaction failed... something went wrong')
      }
      setUsdt(0);
      setQuaniToken(0)

    } catch (error) {
      alert(error.message);
    }
  };

  useEffect(() => {
    if (!wallet) {
      const fetchData = async () => {
        try {
          const quaniInstance = new web3.eth.Contract(QuaniABI, QuaniAddress);
          const stakingInstance = new web3.eth.Contract(QuaniStakingABI, QuaniStakingAddress);

          const s_Max_Tokens = await quaniInstance.methods.s_Max_Tokens().call();
          // console.log("MAX_TOKENS",s_Max_Tokens);
          const finalTotalSupply = web3.utils.fromWei(s_Max_Tokens, "ether");
          settotalSupply(finalTotalSupply);
          let finalSTotalSupply = await quaniInstance.methods.balanceOf('0x8D92bB409ddb6Fa4f5a808BA0C4C66ABd875FC26').call();
          finalSTotalSupply = web3.utils.fromWei(finalSTotalSupply, "ether");
          setCurrentrelease(finalSTotalSupply);
          const currenStakedTotal = await stakingInstance.methods.s_totalStakedTokens().call()
          const finalTotalSta = web3.utils.fromWei(currenStakedTotal, "ether");
          console.log("finalTotalSta", finalTotalSta)
          setlocked(finalTotalSta)
          const totalStakedTokens = await stakingInstance.methods.s_totalStakedTokens().call();
          const finalTotalStakedTokens = web3.utils.fromWei(totalStakedTokens, "ether");
          setTotalStakedTokens(finalTotalStakedTokens);

          // const s_annualRewardPercentage = await stakingInstance.methods
          //   .s_annualRewardPercentage()
          //   .call();
          // setApy(s_annualRewardPercentage / 10);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
    else if (wallet) {

      ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any')
      signer = ethersProvider.getSigner();
      userAddress = wallet.accounts[0];
      let USERADDRESS = userAddress.address;
      const quaniInstance = new ethers.Contract(QuaniAddress, QuaniABI, signer);
      const stakingInstance = new ethers.Contract(QuaniStakingAddress, QuaniStakingABI, signer);
      const usdtInstance = new ethers.Contract(usdtData.address, usdtData.abi, signer)
      const fetchData = async () => {
        try {
          const userUSDTBal = await usdtInstance.balanceOf(USERADDRESS);
          const formatUSDT = ethers.utils.formatUnits(userUSDTBal);
          setUserUSDTBalance(formatUSDT)
          const userTokenBalance = await quaniInstance.balanceOf(USERADDRESS);
          const formattedBalance = ethers.utils.formatUnits(userTokenBalance);        //user Qunai Balance
          console.log("formattedBalance", formattedBalance)
          setUserBalance(formattedBalance);
          setUSERTotalQuaniBalance(formattedBalance);

          const userStakedTokens = await stakingInstance.AddressToStakingDetails(USERADDRESS);
          const finalUserStakedTokens = ethers.utils.formatUnits(userStakedTokens.stakedAmount);      // user staked Tokens
          setUserSraked(finalUserStakedTokens)
          const finalClaimableTokens = ethers.utils.formatUnits(userStakedTokens.claimableRewards);
          let resultString = finalClaimableTokens.toString().slice(0, 8);             // user claimable tokens
          setUserClaimable(resultString);

        } catch (error) {
          console.error('Error fetching data:', error.message);
        }
      }
      fetchData();
    }
  }, [USERTotalQuaniBalance, UserUSDTBalance, wallet, totalSupply, currentRelesae, totalStakedTokens, userBalance, userStaked, userClaimable
    // APY
  ]);

  useEffect(() => {
    fetchBuyPrice()
  }, []);

  return (
    <>
      <div class="wrap">
        <div class="content content-fluid">
          <div class="container-xl wide-xl">
            <div class="content-body">
              <HeaderNavbar />
              <div class="row">
                <div class="col-lg-4">
                  <div class="card-title">
                    {
                      !wallet ? (
                        <h6 class="title">QUANI Stats</h6>)
                        :
                        (<h6 class="title">Your Stats
                        </h6>)}

                  </div>
                  <div class="card-bodys c_bg_1 card-body_img ">
                    <div className="d-flex align-items-center justify-content-between" >
                      <img
                        src="assets/img/ri_1.png"
                        width="50"
                        alt=""
                        class="img-fluid"
                      />
                      {!wallet ? (
                        <h6 className="text-dark" >${totalSupply * PRICE_OF_QUANI}</h6>) : (<h6 className="text-dark" >${userBalance * PRICE_OF_QUANI}</h6>
                      )}
                      {/* <h6 className="text-dark" > <b>${totalSupply * PRICE_OF_QUANI}</b> </h6> */}
                    </div>
                    {!wallet ? (
                      <span>Total Supply</span>) : (<span>User Balance</span>
                    )}
                    {!wallet ? (
                      <h4>{totalSupply}</h4>) : (<h4>{userBalance}</h4>
                    )}


                  </div>
                  <div class="card-bodys c_bg_2   card-body_img ">
                    <div className="d-flex align-items-center justify-content-between" >
                      <img
                        src="assets/img/ri2.png"
                        width="50"
                        alt=""
                        class="img-fluid"
                      />
                      {!wallet ? (
                        <h6 className="text-dark" >${currentRelesae * PRICE_OF_QUANI}</h6>) : (<h6 className="text-dark" >${userStaked * PRICE_OF_QUANI}</h6>
                      )}
                    </div>
                    {!wallet ? (
                      <span>Current Supply</span>) : (<span>User Staked Tokens</span>
                    )}
                    {!wallet ? (
                      <h4>{currentRelesae}</h4>) : (<h4>{userStaked}</h4>
                    )}
                  </div>
                  <div class="card-bodys c_bg_3  mb-0 card-body_img">
                    <div className="d-flex align-items-center justify-content-between" >
                      <img
                        src="assets/img/ri3.png"
                        width="50"
                        alt=""
                        class="img-fluid"
                      />
                      {!wallet ? (
                        <h6 className="text-dark" >${lockedTokens * PRICE_OF_QUANI}</h6>) : (<h6 className="text-dark" >${userClaimable * PRICE_OF_QUANI}</h6>
                      )}
                    </div>
                    {!wallet ? (
                      <span>Total Staked Tokens</span>) : (<span>User Claimable Tokens</span>
                    )}
                    {!wallet ? (
                      <h4>{totalStakedTokens}</h4>) : (<h4>{userClaimable}</h4>
                    )}
                  </div>
                </div>
                <div class="col-lg-8  ">
                  <div class="card-title">
                    {!wallet ? (
                      <h6 class="title">Market Data</h6>) : (<h6>Buy </h6>
                    )}
                  </div>
                  <div class="card c_height">
                    {!wallet ? (
                      <h6 class="title"></h6>)
                      :
                      (

                        <div class="card  " >
                          <div class="card-body" >
                            <form>
                              {!isReverse ?
                                <>
                                  <div class="form-group " >
                                    <label>Enter Quani Token Amount   </label>


                                    <input type="number" class="form-control" placeholder="Enter Amount" onWheel={(e) => e.target.blur()} value={quaniToken === undefined ? 0 : quaniToken} onChange={(e) => { setQuaniToken(e.target.value); setPercentage(false); setUsdt(PRICE_OF_QUANI * e.target.value); setPercentageOption("") }} />
                                  </div>

                                  <div className="py-2 d-flex justify-contennt-center text-center">
                                    <i class="ri-arrow-up-down-fill ar_hs  mx-auto cursor-pointer" style={{ fontSize: "20px" }} onClick={() => { setIsReverse((prev) => !prev); setUsdt(0); setQuaniToken(0); setPercentageOption("") }}></i>
                                  </div>

                                  <div class="form-group" >
                                    <label>Enter USDT    </label>
                                    <input type="number" class="form-control" placeholder="Enter Amount" value={usdt} />
                                  </div>

                                  <div className="row g-2 mb-4" >

                                    <div className="col-3" >
                                      <button className={`btn btn_gradient btn-sm  w-100 ${percentageOption === 25 && 'active'}`} style={{ padding: "0px 12px", marginRight: "4px" }} type="button" onClick={() => {
                                        try {
                                          if (!wallet) {
                                            connect();
                                          } else {
                                            setPercentageOption(25)
                                            setUsdt(UserUSDTBalance === undefined ? 0 : UserUSDTBalance * 0.25); setPercentage(true); setQuaniToken((UserUSDTBalance * 0.25) / PRICE_OF_QUANI)
                                          }
                                        }
                                        catch (error) { alert("Error") }
                                      }}>25%</button>

                                    </div>
                                    <div className="col-3" >

                                      <button className={`btn btn_gradient btn-sm  w-100 ${percentageOption === 50 && 'active'}`} style={{ padding: "0px 12px", marginRight: "4px" }} type="button" onClick={() => {
                                        try {
                                          if (!wallet) {
                                            connect();
                                          }
                                          else {
                                            setPercentageOption(50)
                                            setUsdt(UserUSDTBalance === undefined ? 0 : UserUSDTBalance * 0.50); setPercentage(true); setQuaniToken((UserUSDTBalance * 0.50) / PRICE_OF_QUANI)
                                          }
                                        } catch (error) {
                                          alert("Error")
                                        }
                                      }}>50%</button>

                                    </div>
                                    <div className="col-3" >

                                      <button className={`btn btn_gradient btn-sm  w-100 ${percentageOption === 75 && 'active'}`} style={{ padding: "0px 12px", marginRight: "4px" }} type="button" onClick={() => {
                                        try {
                                          if (!wallet) {
                                            connect();
                                          }
                                          else {
                                            setPercentageOption(75)
                                            setUsdt(UserUSDTBalance === undefined ? 0 : UserUSDTBalance * 0.75); setPercentage(true); setQuaniToken((UserUSDTBalance * 0.75) / PRICE_OF_QUANI)
                                          }
                                        } catch (error) {
                                          alert("Error")
                                        }
                                      }}>75%</button>

                                    </div>
                                    <div className="col-3" >

                                      <button className={`btn btn_gradient btn-sm  w-100 ${percentageOption === 100 && 'active'}`} style={{ padding: "0px 12px", marginRight: "4px" }} type="button" onClick={() => {
                                        try {
                                          if (!wallet) {
                                            connect();
                                          } else {
                                            setPercentageOption(100)
                                            setUsdt(UserUSDTBalance === undefined ? 0 : UserUSDTBalance); setPercentage(true); setQuaniToken((UserUSDTBalance) / PRICE_OF_QUANI)
                                          }
                                        } catch (error) {
                                          alert("Error")
                                        }
                                      }}>100%</button>
                                    </div>
                                  </div>

                                </> : <>

                                  <div class="form-group  " >
                                    <label>Enter USDT   </label>


                                    <input type="number" class="form-control" placeholder="Enter Amount" value={usdt} onWheel={(e) => e.target.blur()} onChange={(e) => { setUsdt(e.target.value); setPercentage(false); setQuaniToken(e.target.value / PRICE_OF_QUANI); setPercentageOption("") }} />
                                  </div>

                                  <div className="py-2 d-flex justify-contennt-center text-center">
                                    <i class="ri-arrow-up-down-fill ar_hs  mx-auto cursor-pointer" style={{ fontSize: "20px" }} onClick={() => { setIsReverse((prev) => !prev); setUsdt(0); setQuaniToken(0); setPercentageOption("") }}></i>
                                  </div>


                                  <div class="form-group  " >
                                    <label>Enter Quani Token Amount   </label>
                                    <input type="text" class="form-control" placeholder="Enter Amount" value={quaniToken} />
                                  </div>


                                  <div className="row g-2 mb-4" >
                                    <div className="col-3" >
                                      <button className={`btn btn_gradient btn-sm  w-100 ${percentageOption === 25 && 'active'}`} style={{ padding: "0px 12px", marginRight: "4px" }} type="button" onClick={() => {
                                        try {
                                          if (!wallet) {
                                            connect()
                                          } else {
                                            setPercentageOption(25)
                                            setUsdt(UserUSDTBalance === undefined ? 0 : UserUSDTBalance * 0.25); setPercentage(true); setQuaniToken((UserUSDTBalance * 0.25) / PRICE_OF_QUANI)
                                          }
                                        } catch (error) {
                                          alert("Error")
                                        }

                                      }}>25%</button>
                                    </div>

                                    <div className="col-3" >
                                      <button className={`btn btn_gradient btn-sm  w-100 ${percentageOption === 50 && 'active'}`} style={{ padding: "0px 12px", marginRight: "4px" }} type="button" onClick={() => {
                                        try {
                                          if (!wallet) {
                                            connect();
                                          } else {
                                            setPercentageOption(50)
                                            setUsdt(UserUSDTBalance === undefined ? 0 : UserUSDTBalance * 0.50); setPercentage(true); setQuaniToken((UserUSDTBalance * 0.50) / PRICE_OF_QUANI)
                                          }
                                        } catch (error) {
                                          alert("Error")
                                        }
                                      }}>50%</button>
                                    </div>

                                    <div className="col-3" >
                                      <button className={`btn btn_gradient btn-sm  w-100 ${percentageOption === 75 && 'active'}`} style={{ padding: "0px 12px", marginRight: "4px" }} type="button" onClick={() => {
                                        try {
                                          if (!wallet) {
                                            connect();
                                          } else {
                                            setPercentageOption(75)
                                            setUsdt(UserUSDTBalance === undefined ? 0 : UserUSDTBalance * 0.75); setPercentage(true); setQuaniToken((UserUSDTBalance * 0.75) / PRICE_OF_QUANI)
                                          }
                                        } catch (error) {
                                          alert("Error")
                                        }
                                      }}>75%</button>
                                    </div>

                                    <div className="col-3" >
                                      <button className={`btn btn_gradient btn-sm  w-100 ${percentageOption === 100 && 'active'}`} style={{ padding: "0px 12px", marginRight: "4px" }} type="button" onClick={() => {
                                        try {
                                          if (!wallet) {
                                            connect()
                                          } else {
                                            setPercentageOption(100)
                                            setUsdt(UserUSDTBalance === undefined ? 0 : UserUSDTBalance); setPercentage(true); setQuaniToken((UserUSDTBalance) / PRICE_OF_QUANI)
                                          }
                                        } catch (error) {
                                          alert("Error")
                                        }
                                      }}>100%</button>
                                    </div>
                                  </div>
                                </>}

                              <div class="balance-libra card-success mb-4">
                                <div class="token-img-bg_right" id="lc-data"> 1 Quani Token  = {PRICE_OF_QUANI} BSC-USD</div>
                                <div class="token-img-bg_right" id="lc-data"> Network : BNB Smart Chain Mainnet</div>
                              </div>

                              <div class="form-group mb-0" >
                                <button class="btn btn-primary btn_gradient btn-block w-100" type="button" onClick={() => BuyOrSwap()} disabled={!quaniToken || !usdt}
                                > Buy Quani Token   </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      )}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default Dashboard;
