import QalaxyFooter from "../CustomComponents/QalaxyFooter"
import QalaxyHeader from "../CustomComponents/QalaxyHeader"

const About = () =>{
    return(
        <>
         <div class="site-content pt-0">
            {/* <!-- header-start--> */}
           {/* <QalaxyHeader/> */}
            {/* <!-- header-closed--> */}
             
            <section class="about-comman-center hero-banner-full full-banner pt-165">
               <span class="shape-arounder"> <img src="qalaxyAssets/img/man-shape.png" alt="" / ></span>
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="qal-token-content topkon">
                                <h5>Qalaxy labs</h5>
                                <p>
                                    Founded on the pioneering spirit of the digital age, Qalaxy Labs began as a visionary project to harness the transformative power of blockchain technology. From a passionate idea to a leading innovator in
                                    crypto and NFT solutions, we have grown with the community we serve, building a bridge between technology and everyday life.
                                </p>
                                <div class="comman-know-btn">
                                    <a href="javascript:;">Know more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section class="about-comman-center hero-banner-full">
               
               <div class="container">
                   <div class="row">
                       <div class="col-md-12">
                           <div class="qal-token-content topkon">
                               <h5>  Vision and Mision  </h5>
                               <p>
                                   Mission Statement Our mission is to democratize the future of finance and technology. At Qalaxy Labs, we strive to make cutting-edge blockchain and AI technologies accessible, creating tools that empower individuals to secure their digital future and foster global connectivity.
                               </p>
                               <div class="comman-know-btn">
                                   <a href="javascript:;">Know more</a>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </section>


           <section class="passive-income-wrapper">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="passivemain-inner">
                                <div class="main-waves-span">
                                    <span class="wave1 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s"><img src="qalaxyAssets/img/b1.png" alt="" /></span>
                                    <span class="wave2 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s"><img src="qalaxyAssets/img/b2.png" alt="" /></span>
                                    <span class="wave3 wow fadeInUp" data-wow-duration="3s" data-wow-delay="0.4s"><img src="qalaxyAssets/img/b3.png" alt="" /></span>
                                    <span class="wave4 wow fadeInUp" data-wow-duration="4s" data-wow-delay="0.1s"><img src="qalaxyAssets/img/b4.png" alt="" /></span>
                                    <span class="wave5 wow fadeInUp" data-wow-duration="5s" data-wow-delay="0.2s"><img src="qalaxyAssets/img/b5.png" alt="" /></span>
                                </div>

                                <div class="passive-img">
                                    <figure class="wow slideInUp" data-wow-duration="3s" data-wow-delay="0.3s"><img src="qalaxyAssets/img/yogaman.png" alt="" /></figure>
                                    <span class="waves"> <img src="qalaxyAssets/img/waves.png" class="wow zoomIn" data-wow-duration="1s" data-wow-delay="0.2s" /></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="passive-content top">
                                <h3>
                               <span> Core Values </span>
                                </h3>

                                <p> Innovation, Integrity, and Inclusivity are the pillars of Qalaxy Labs. We believe in pushing the boundaries of what's possible, conducting business with unwavering honesty, and building an environment where every voice can be heard and every idea has the potential to change the world. 
                                </p>
                           
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section class="qal-token-wrapper">
                <div class="container">
       

                    <div class="token-wrapper-inner right full-grid-height">
                        <div class="row align-items-center">
                
                            <div class="col-md-6">
                                <div class="qal-token-content">
                                    <h5>Leadership Team </h5>
                                    <p>
                                        Leadership Team Guided by a team of trailblazers in technology and finance, Qalaxy Labs is spearheaded by individuals renowned for their expertise and commitment to excellence. Our leaders are dedicated to steering us towards new horizons and inspiring our community to reach for the stars.
                                    </p>
                                 
                                </div>
                            </div>

                                        <div class="col-md-6">
                                <div class="qal-token-wrapper-animation">
                                    <div class="wave-passing">
                                        <span class="wave-passing-1 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s"><img src="qalaxyAssets/img/dotes1.png" /></span>
                                        <span class="wave-passing-2 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s"><img src="qalaxyAssets/img/dotes2.png" /></span>
                                        <span class="wave-passing-3 wow fadeInUp" data-wow-duration="3s" data-wow-delay="0.2s"><img src="qalaxyAssets/img/dotes3.png" /></span>
                                    </div>
                                    <div class="passive-img">
                                        <figure class="wow slideInUp" data-wow-duration="3s" data-wow-delay="0.3s"><img src="qalaxyAssets/img/layersman1.png" alt="" /></figure>
                                        <span class="waves"> <img src="qalaxyAssets/img/waves.png" class="wow zoomIn" data-wow-duration="1s" data-wow-delay="0.2s" /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                    
                </div>
            </section>





            {/* <!-- footer-start--> */}
            <QalaxyFooter/>
            {/* <!-- footer-closed--> */}
        </div>
        {/* <!-- meta-mask-modal-start--> */}
        <div class="meta-mask modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="modal-body">
                        <div class="comman-content-meta">
                            <h3>Hey there</h3>
                            <span>you can</span>
                            <h3>connect to wallet</h3>
                            <span>now</span>
                        </div>
                        <div class="select-meta-box">
                            <div class="select-box-comman">
                                <select class="form-select" aria-label="Default select example">
                                    <option selected>metamask</option>
                                    <option value="1">Wallet options</option>
                                </select>
                            </div>
                            <button>confirm</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default About