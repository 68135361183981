import { Link, useLocation } from "react-router-dom"
import { useState } from "react"
import contract from "../UiComponents/Contract.json"

const Sidebar = () => {

    const [activeTab, setActiveTab] = useState('')
    const location = useLocation()

    const openSidebar = () => {
        let para = document.getElementById("sidebar");
        if (para.classList.contains("sidebar-active")) {

            para.classList.remove("sidebar-active");
        }
        else {
            para.classList.add("sidebar-active");
        }
    };

    const tokenAddress = contract.Quaniaddress;
    const { ethereum } = window;


    const addTokenFunction = async () => {
        try {

            const wasAdded = await ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20',
                    options: {
                        address: tokenAddress,
                        symbol: "QUANI",
                        decimals: 18,
                        image: "assets/img/logo.png"
                    },
                },
            });

            if (wasAdded) {
                console.log('Thanks for your interest!');
            } else {
                console.log('Quani Coin has not been added');
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div id="sidebar" class="sidebar sidebar-fixed">
            <div class="sidebar-element sidebar-head">
                <div class="sidebar-brand">
                    <Link class="logo-link" to="/">
                        <img class="logo-light logo-img" src="assets/img/logo.png" alt="logo" />
                    </Link>
                </div>
                <div class="menu-trigger me-n2">
                    <span onClick={openSidebar} class="nav-toggle quick-nav-icon d-xl-none"><em class="icon ri-arrow-left-line"></em></span>
                </div>
            </div>
            <div class="sidebar-content" >
                <div class="sidebar-menu" >
                    <ul class="menu">
                        <li class="menu-heading">
                            <h5 class="overline-title text-primary-alt"> Menu </h5>
                        </li>
                        <li class="menu-item">
                            {console.log(activeTab === 'dashboard','location.pathname?.includes')}
                            <Link className={(activeTab === 'dashboard' || location.pathname?.includes('dashboard')) ? 'menu-link active' : " menu-link"} onClick={() => setActiveTab('dashboard')} to="/dashboard">
                                <span class="menu-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 20 21" fill="none">
                                        <path d="M12.8333 18C11.7288 18 10.8333 17.1046 10.8333 16V11.6667C10.8333 10.5621 11.7288 9.66667 12.8333 9.66667H15.5C16.6046 9.66667 17.5 10.5621 17.5 11.6667V16C17.5 17.1046 16.6046 18 15.5 18H12.8333ZM4.5 11.3333C3.39543 11.3333 2.5 10.4379 2.5 9.33333V5C2.5 3.89543 3.39543 3 4.5 3H7.16667C8.27124 3 9.16667 3.89543 9.16667 5V9.33333C9.16667 10.4379 8.27124 11.3333 7.16667 11.3333H4.5ZM5.83333 9.66667C6.75381 9.66667 7.5 8.92047 7.5 8V6.33333C7.5 5.41286 6.75381 4.66667 5.83333 4.66667C4.91286 4.66667 4.16667 5.41286 4.16667 6.33333V8C4.16667 8.92047 4.91286 9.66667 5.83333 9.66667ZM4.5 18C3.39543 18 2.5 17.1046 2.5 16V15C2.5 13.8954 3.39543 13 4.5 13H7.16667C8.27124 13 9.16667 13.8954 9.16667 15V16C9.16667 17.1046 8.27124 18 7.16667 18H4.5ZM4.16667 15.5C4.16667 15.9602 4.53976 16.3333 5 16.3333H6.66667C7.1269 16.3333 7.5 15.9602 7.5 15.5C7.5 15.0398 7.1269 14.6667 6.66667 14.6667H5C4.53976 14.6667 4.16667 15.0398 4.16667 15.5ZM12.5 14.6667C12.5 15.5871 13.2462 16.3333 14.1667 16.3333C15.0871 16.3333 15.8333 15.5871 15.8333 14.6667V13C15.8333 12.0795 15.0871 11.3333 14.1667 11.3333C13.2462 11.3333 12.5 12.0795 12.5 13V14.6667ZM10.8333 5C10.8333 3.89543 11.7288 3 12.8333 3H15.5C16.6046 3 17.5 3.89543 17.5 5V6C17.5 7.10457 16.6046 8 15.5 8H12.8333C11.7288 8 10.8333 7.10457 10.8333 6V5ZM13.3333 4.66667C12.8731 4.66667 12.5 5.03976 12.5 5.5C12.5 5.96024 12.8731 6.33333 13.3333 6.33333H15C15.4602 6.33333 15.8333 5.96024 15.8333 5.5C15.8333 5.03976 15.4602 4.66667 15 4.66667H13.3333Z" fill="white" />
                                    </svg>
                                </span>
                                <span class="menu-text" onClick={openSidebar}> Dashboard</span>
                            </Link>
                        </li>
                        <li class="menu-item">
                            <Link className={activeTab === 'staking' || location.pathname?.includes('staking') ? 'menu-link active' : " menu-link"} onClick={() => setActiveTab('staking')} to="/staking">
                                <span class="menu-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 20 21" fill="none">
                                        <path d="M3.33333 2.16667H16.6667C17.1269 2.16667 17.5 2.53977 17.5 3.00001V18C17.5 18.4603 17.1269 18.8333 16.6667 18.8333H3.33333C2.8731 18.8333 2.5 18.4603 2.5 18V3.00001C2.5 2.53977 2.8731 2.16667 3.33333 2.16667ZM4.16667 3.83334V17.1667H15.8333V3.83334H4.16667ZM5.83333 5.50001H14.1667V8.83334H5.83333V5.50001ZM5.83333 10.5H7.5V12.1667H5.83333V10.5ZM5.83333 13.8333H7.5V15.5H5.83333V13.8333ZM9.16667 10.5H10.8333V12.1667H9.16667V10.5ZM9.16667 13.8333H10.8333V15.5H9.16667V13.8333ZM12.5 10.5H14.1667V15.5H12.5V10.5Z" fill="white" />
                                    </svg>
                                </span>
                                <span class="menu-text" onClick={openSidebar}>  Staking</span>
                            </Link>
                        </li>
                        <li class="menu-item">
                            <Link className={activeTab === 'swap'|| location.pathname?.includes('swap')  ? 'menu-link active' : " menu-link"} onClick={() => setActiveTab('swap')} to="/swap">
                                <span class="menu-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 20 21" fill="none">
                                        <path d="M14.9287 3.00002C14.6032 2.67458 14.0756 2.67457 13.7501 3L13.7205 3.02963C13.4114 3.33871 13.4114 3.83985 13.7205 4.14893C14.2191 4.64752 13.866 5.50002 13.1609 5.50002H11.6668C11.2066 5.50002 10.8335 5.87312 10.8335 6.33335C10.8335 6.79359 11.2066 7.16668 11.6668 7.16668H13.1609C13.866 7.16668 14.2191 8.01918 13.7205 8.51777C13.4114 8.82685 13.4114 9.32799 13.7205 9.63706L13.7501 9.6667C14.0756 9.99212 14.6032 9.99211 14.9287 9.66668L17.5549 7.04046C17.9454 6.64993 17.9454 6.01677 17.5549 5.62624L14.9287 3.00002ZM7.91679 6.33335C7.91679 5.18276 6.98405 4.25002 5.83346 4.25002C4.68287 4.25002 3.75013 5.18276 3.75013 6.33335C3.75013 7.48395 4.68287 8.41668 5.83346 8.41668C6.98405 8.41668 7.91679 7.48395 7.91679 6.33335ZM9.5835 6.33335C9.5835 8.40442 7.90453 10.0833 5.83346 10.0833C3.76239 10.0833 2.08346 8.40442 2.08346 6.33335C2.08346 4.26228 3.76239 2.58335 5.83346 2.58335C7.90453 2.58335 9.5835 4.26228 9.5835 6.33335ZM6.83938 15.5C6.13427 15.5 5.78115 16.3525 6.27974 16.8511C6.58882 17.1602 6.58882 17.6613 6.27973 17.9704L6.25012 18C5.92469 18.3254 5.39705 18.3254 5.07162 18L2.44539 15.3738C2.05487 14.9833 2.05487 14.3501 2.44539 13.9596L5.07162 11.3333C5.39705 11.0079 5.92469 11.0079 6.25012 11.3333L6.27973 11.3629C6.58882 11.672 6.58882 12.1732 6.27974 12.4822C5.78115 12.9808 6.13427 13.8333 6.83938 13.8333H8.3335C8.79374 13.8333 9.16684 14.2064 9.16684 14.6667C9.16684 15.1269 8.79374 15.5 8.3335 15.5H6.83938ZM12.5002 15.3333C12.5002 15.8856 12.9479 16.3333 13.5002 16.3333H14.8335C15.3858 16.3333 15.8335 15.8856 15.8335 15.3333V14C15.8335 13.4477 15.3858 13 14.8335 13H13.5002C12.9479 13 12.5002 13.4477 12.5002 14V15.3333ZM10.8335 12.1667C10.8335 11.7064 11.2066 11.3333 11.6668 11.3333H16.6668C17.127 11.3333 17.5002 11.7064 17.5002 12.1667V17.1667C17.5002 17.6269 17.127 18 16.6668 18H11.6668C11.2066 18 10.8335 17.6269 10.8335 17.1667V12.1667Z" fill="white" />
                                    </svg>
                                </span>
                                <span class="menu-text" onClick={openSidebar}>Buy</span>
                            </Link>
                        </li>
                        <li class="menu-item">
                            <Link className={activeTab === 'transactions'|| location.pathname?.includes('transactions')  ? 'menu-link active' : " menu-link"} onClick={() => setActiveTab('transactions')} to="/transactions">
                                <span class="menu-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="28" height="28" fill="nonw">
                                        <path fill="white" d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM13 12H17V14H11V7H13V12Z"></path>
                                    </svg>
                                </span>
                                <span class="menu-text" onClick={openSidebar}>Transactions  </span>
                            </Link>
                        </li>
                        <li class="menu-item">
                            <Link className={activeTab === 'incubation'|| location.pathname?.includes('incubation')  ? 'menu-link active' : " menu-link"} onClick={() => setActiveTab('incubation')} to="/incubation">
                                <span class="menu-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 20 21" fill="none">
                                        <path d="M0.916835 15.1667C1.14695 15.1667 1.3335 14.9801 1.3335 14.75V10.1667C1.3335 6.48476 4.31827 3.49999 8.00016 3.49999C11.6821 3.49999 14.6668 6.48476 14.6668 10.1667V14.75C14.6668 14.9801 14.8534 15.1667 15.0835 15.1667C15.3136 15.1667 15.5002 15.3532 15.5002 15.5833V16C15.5002 16.4602 15.1271 16.8333 14.6668 16.8333H1.3335C0.873264 16.8333 0.500169 16.4602 0.500169 16V15.5833C0.500169 15.3532 0.686717 15.1667 0.916835 15.1667ZM3.00017 13.1667C3.00017 14.2712 3.8956 15.1667 5.00017 15.1667H11.0002C12.1047 15.1667 13.0002 14.2712 13.0002 13.1667V10.1667C13.0002 7.40524 10.7616 5.16666 8.00016 5.16666C5.23874 5.16666 3.00017 7.40524 3.00017 10.1667V13.1667ZM7.16683 0.99999C7.16683 0.539753 7.53992 0.166656 8.00016 0.166656C8.4604 0.166656 8.83349 0.539753 8.83349 0.99999V1.83332C8.83349 2.29356 8.4604 2.66666 8.00016 2.66666C7.53992 2.66666 7.16683 2.29356 7.16683 1.83332V0.99999ZM13.8927 3.0956C14.2182 2.77016 14.7458 2.77016 15.0713 3.0956C15.3967 3.42103 15.3967 3.94866 15.0713 4.2741L14.482 4.86335C14.1566 5.18879 13.6289 5.18879 13.3035 4.86335C12.9781 4.53791 12.9781 4.01028 13.3035 3.68485L13.8927 3.0956ZM0.929098 4.2741C0.603661 3.94867 0.603661 3.42103 0.929096 3.09559C1.25453 2.77015 1.78217 2.77015 2.10761 3.09559L2.69686 3.68484C3.0223 4.01028 3.0223 4.53792 2.69687 4.86335C2.37143 5.18879 1.84379 5.18879 1.51835 4.86336L0.929098 4.2741ZM4.66684 10.1667C4.2066 10.1667 3.82481 9.7898 3.91578 9.33864C4.24582 7.70175 5.53524 6.41231 7.17215 6.08226C7.62331 5.9913 8.00016 6.37309 8.00016 6.83332C8.00016 7.29356 7.61682 7.65306 7.18192 7.80364C6.45944 8.05377 5.88728 8.62591 5.63714 9.34841C5.48657 9.78332 5.12707 10.1667 4.66684 10.1667Z" fill="white" />
                                    </svg>
                                </span>
                                <span class="menu-text" onClick={openSidebar}>  Incubation</span>
                            </Link>
                        </li>
                        <li class="menu-item">
                            <Link className={activeTab === 'partners'|| location.pathname?.includes('partners')  ? 'menu-link active' : " menu-link"} onClick={() => setActiveTab('partners')} to="/partners">
                                <span class="menu-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 20 21" fill="none">
                                        <path d="M1.66667 18.8333C1.66667 15.1514 4.65143 12.1667 8.33333 12.1667C12.0153 12.1667 15 15.1514 15 18.8333H13.3333C13.3333 16.0719 11.0948 13.8333 8.33333 13.8333C5.57191 13.8333 3.33333 16.0719 3.33333 18.8333H1.66667ZM8.33333 11.3333C5.57083 11.3333 3.33333 9.09584 3.33333 6.33334C3.33333 3.57084 5.57083 1.33334 8.33333 1.33334C11.0958 1.33334 13.3333 3.57084 13.3333 6.33334C13.3333 9.09584 11.0958 11.3333 8.33333 11.3333ZM8.33333 9.66668C10.175 9.66668 11.6667 8.17501 11.6667 6.33334C11.6667 4.49168 10.175 3.00001 8.33333 3.00001C6.49167 3.00001 5 4.49168 5 6.33334C5 8.17501 6.49167 9.66668 8.33333 9.66668ZM15.2364 12.7523C17.5537 13.7968 19.1667 16.1267 19.1667 18.8333H17.5C17.5 16.8033 16.2902 15.0559 14.5523 14.2726L15.2364 12.7523ZM14.6635 3.34435C16.3287 4.03087 17.5 5.66968 17.5 7.58334C17.5 9.97518 15.6702 11.9377 13.3333 12.148V10.4705C14.7473 10.2685 15.8333 9.05334 15.8333 7.58334C15.8333 6.4328 15.168 5.43837 14.2008 4.96363L14.6635 3.34435Z" fill="white" />
                                    </svg>
                                </span>
                                <span class="menu-text" onClick={openSidebar}>  Partners</span>
                            </Link>
                        </li>
                        <li class="menu-item">
                            <Link className={activeTab === 'faq'|| location.pathname?.includes('faq')  ? 'menu-link active' : " menu-link"} onClick={() => setActiveTab('faq')} to="/faq">
                                <span class="menu-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 20 21" fill="none">
                                        <path d="M10 18.8333C5.39763 18.8333 1.66667 15.1023 1.66667 10.5C1.66667 5.89761 5.39763 2.16666 10 2.16666C14.6023 2.16666 18.3333 5.89761 18.3333 10.5C18.3333 15.1023 14.6023 18.8333 10 18.8333ZM10 17.1667C13.6819 17.1667 16.6667 14.1819 16.6667 10.5C16.6667 6.81809 13.6819 3.83332 10 3.83332C6.3181 3.83332 3.33333 6.81809 3.33333 10.5C3.33333 14.1819 6.3181 17.1667 10 17.1667ZM9.16667 13H10.8333V14.6667H9.16667V13ZM10.8333 11.6292V12.1667H9.16667V10.9167C9.16667 10.4564 9.53975 10.0833 10 10.0833C10.6903 10.0833 11.25 9.52366 11.25 8.83332C11.25 8.14296 10.6903 7.58332 10 7.58332C9.39358 7.58332 8.888 8.01518 8.774 8.58813L7.13943 8.26121C7.40531 6.92431 8.585 5.91666 10 5.91666C11.6108 5.91666 12.9167 7.22249 12.9167 8.83332C12.9167 10.1546 12.0381 11.2707 10.8333 11.6292Z" fill="white" />
                                    </svg>
                                </span>
                                <span class="menu-text" onClick={openSidebar}>FAQ</span>
                            </Link>
                        </li>
                        <li class="menu-item">
                            <Link className={activeTab === 'nft'|| location.pathname?.includes('nft')  ? 'menu-link active' : " menu-link"} onClick={() => setActiveTab('nft')} to="/nft">
                                <span class="menu-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 20 21" fill="none">
                                        <path d="M5.5 9.49998C6.4205 9.49998 7.16667 8.75381 7.16667 7.83331C7.16667 6.91284 6.4205 6.16665 5.5 6.16665C4.57952 6.16665 3.83333 6.91284 3.83333 7.83331C3.83333 8.75381 4.57952 9.49998 5.5 9.49998ZM15.9167 6.06975C15.9167 5.35608 15.5364 4.69648 14.9187 4.3389L9.00207 0.913461C8.38222 0.554597 7.61778 0.554597 6.99793 0.91346L1.08126 4.3389C0.463625 4.69648 0.0833321 5.35608 0.0833321 6.06975V12.9302C0.0833321 13.6439 0.463625 14.3035 1.08126 14.6611L6.99793 18.0865C7.61778 18.4454 8.38222 18.4454 9.00207 18.0865L14.9187 14.6611C15.5364 14.3035 15.9167 13.6439 15.9167 12.9302V6.06975ZM6.99793 2.83929C7.61778 2.48043 8.38222 2.48043 9.00207 2.83929L13.2521 5.29982C13.8697 5.6574 14.25 6.317 14.25 7.03068V8.40041C14.25 9.8136 12.7083 10.6865 11.4965 9.95943C10.8627 9.57912 10.0616 9.62044 9.47027 10.064L4.82058 13.5512C4.20508 14.0128 3.37339 14.0623 2.70756 13.6768C2.11491 13.3337 1.75 12.7007 1.75 12.0159V7.03068C1.75 6.317 2.13029 5.6574 2.74793 5.29982L6.99793 2.83929ZM8.72709 16.3199C8.27733 16.5802 7.72266 16.5803 7.2729 16.3199C6.37256 15.7986 6.29701 14.5272 7.12928 13.903L9.66961 11.9978C10.203 11.5977 10.9255 11.5604 11.4973 11.9035C12.5685 12.5462 12.5563 14.1029 11.4752 14.7288L8.72709 16.3199Z" fill="white" />
                                    </svg>
                                </span>
                                <span class="menu-text" onClick={openSidebar}>  NFT</span>
                            </Link>
                        </li>
                        <li class="menu-item">
                            <Link className={activeTab === 'nftLottery'|| location.pathname?.includes('nftLottery')  ? 'menu-link active' : " menu-link"} onClick={() => setActiveTab('nftLottery')} to="/nftLottery">
                                <span class="menu-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 20 21" fill="none">
                                        <path d="M5.5 9.49998C6.4205 9.49998 7.16667 8.75381 7.16667 7.83331C7.16667 6.91284 6.4205 6.16665 5.5 6.16665C4.57952 6.16665 3.83333 6.91284 3.83333 7.83331C3.83333 8.75381 4.57952 9.49998 5.5 9.49998ZM15.9167 6.06975C15.9167 5.35608 15.5364 4.69648 14.9187 4.3389L9.00207 0.913461C8.38222 0.554597 7.61778 0.554597 6.99793 0.91346L1.08126 4.3389C0.463625 4.69648 0.0833321 5.35608 0.0833321 6.06975V12.9302C0.0833321 13.6439 0.463625 14.3035 1.08126 14.6611L6.99793 18.0865C7.61778 18.4454 8.38222 18.4454 9.00207 18.0865L14.9187 14.6611C15.5364 14.3035 15.9167 13.6439 15.9167 12.9302V6.06975ZM6.99793 2.83929C7.61778 2.48043 8.38222 2.48043 9.00207 2.83929L13.2521 5.29982C13.8697 5.6574 14.25 6.317 14.25 7.03068V8.40041C14.25 9.8136 12.7083 10.6865 11.4965 9.95943C10.8627 9.57912 10.0616 9.62044 9.47027 10.064L4.82058 13.5512C4.20508 14.0128 3.37339 14.0623 2.70756 13.6768C2.11491 13.3337 1.75 12.7007 1.75 12.0159V7.03068C1.75 6.317 2.13029 5.6574 2.74793 5.29982L6.99793 2.83929ZM8.72709 16.3199C8.27733 16.5802 7.72266 16.5803 7.2729 16.3199C6.37256 15.7986 6.29701 14.5272 7.12928 13.903L9.66961 11.9978C10.203 11.5977 10.9255 11.5604 11.4973 11.9035C12.5685 12.5462 12.5563 14.1029 11.4752 14.7288L8.72709 16.3199Z" fill="white" />
                                    </svg>
                                </span>
                                <span class="menu-text" onClick={openSidebar}>  NFT lottery</span>
                            </Link>
                        </li>
                    </ul>

                </div>
            </div>
            <div className="sidebar_action" >
                <button class="btn btn_gradient btn-sm" onClick={addTokenFunction} > <span> Add Quani to Wallet   </span></button>
            </div>
        </div>
    )
}


export default Sidebar